import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-history-of-armenia',
  templateUrl: './history-of-armenia.component.html',
  styleUrls: ['./history-of-armenia.component.scss'],
})
export class HistoryOfArmeniaComponent implements OnInit {
  currentLng:string = 'am'
  constructor(private service:LanguageService) {
    this.service.getLanguage.subscribe(res=>{
      this.currentLng = res;
    })
  }

  historys: Array<any> = [
    {
      am:{
        img: '/assets/images/history/great_hayq.gif',
        name: 'Մեծ Հայք',
        url: 'https://hy.wikipedia.org/wiki/%D5%84%D5%A5%D5%AE_%D5%80%D5%A1%D5%B5%D6%84',
      },
      ru:{
        img: '/assets/images/history/great_hayq.gif',
        name: 'Великая Армения',
        url: 'https://ru.wikipedia.org/wiki/%D0%92%D0%B5%D0%BB%D0%B8%D0%BA%D0%B0%D1%8F_%D0%90%D1%80%D0%BC%D0%B5%D0%BD%D0%B8%D1%8F',
      },
      en:{
        img: '/assets/images/history/great_hayq.gif',
        name: 'Great Hayq',
        url: 'https://en.wikipedia.org/wiki/Provinces_of_the_kingdom_of_Armenia_(antiquity)',
      }
    },
    {
      am:{
        img: '/assets/images/history/Tigran_mets.jpg',
        name: 'Տիգրան Մեծ',
        url: 'https://hy.wikipedia.org/wiki/%D5%8F%D5%AB%D5%A3%D6%80%D5%A1%D5%B6_%D5%84%D5%A5%D5%AE',
      },
      ru:{
        img: '/assets/images/history/Tigran_mets.jpg',
        name: 'Тигран II',
        url: 'https://ru.wikipedia.org/wiki/%D0%A2%D0%B8%D0%B3%D1%80%D0%B0%D0%BD_II',
      },
      en:{
        img: '/assets/images/history/Tigran_mets.jpg',
        name: 'Tigranes the Great',
        url: 'https://en.wikipedia.org/wiki/Tigranes_the_Great',
      }
    },
    {
      am:{
        img: '/assets/images/history/hayk.png',
        name: 'Հայկ նահապետ',
        url: 'https://hy.wikipedia.org/wiki/%D5%80%D5%A1%D5%B5%D5%AF_%D5%B6%D5%A1%D5%B0%D5%A1%D5%BA%D5%A5%D5%BF',
      },
      ru:{
        img: '/assets/images/history/hayk.png',
        name: 'Айк',
        url: 'https://ru.wikipedia.org/wiki/%D0%90%D0%B9%D0%BA_(%D0%BC%D0%B8%D1%84%D1%8B)',
      },
      en:{
        img: '/assets/images/history/hayk.png',
        name: 'Hayk',
        url: 'https://en.wikipedia.org/wiki/Hayk',
      }
    },
    {
      am:{
        img: '/assets/images/history/sasuntsi_davit.jpg',
        name: 'Սասունցի Դավիթ',
        url: 'https://hy.wikipedia.org/wiki/%D5%8D%D5%A1%D5%BD%D5%B8%D6%82%D5%B6%D6%81%D5%AB_%D4%B4%D5%A1%D5%BE%D5%AB%D5%A9',
      },
      ru:{
        img: '/assets/images/history/sasuntsi_davit.jpg',
        name: 'Давид Сасунский',
        url: 'https://ru.wikipedia.org/wiki/%D0%94%D0%B0%D0%B2%D0%B8%D0%B4_%D0%A1%D0%B0%D1%81%D1%83%D0%BD%D1%81%D0%BA%D0%B8%D0%B9',
      },
      en:{
        img: '/assets/images/history/sasuntsi_davit.jpg',
        name: 'David of Sassoun',
        url: 'https://en.wikipedia.org/wiki/Daredevils_of_Sassoun',
      }
    },
    {
      am:{
        img: '/assets/images/history/noy.jpg',
        name: 'Նոյ',
        url: 'https://hy.wikipedia.org/wiki/%D5%86%D5%B8%D5%B5',
      },
      ru:{
        img: '/assets/images/history/noy.jpg',
        name: 'Ной',
        url: 'https://ru.wikipedia.org/wiki/%D0%9D%D0%BE%D0%B9',
      },
      en:{
        img: '/assets/images/history/noy.jpg',
        name: 'Noah',
        url: 'https://en.wikipedia.org/wiki/Noah',
      }
    },
    {
      am:{
        img: '/assets/images/history/qarahunj.jpg',
        name: 'Քարահունջ',
        url: 'https://hy.wikipedia.org/wiki/%D5%94%D5%A1%D6%80%D5%A1%D5%B0%D5%B8%D6%82%D5%B6%D5%BB',
      },
      ru:{
        img: '/assets/images/history/qarahunj.jpg',
        name: 'Зорац-Карер',
        url: 'https://ru.wikipedia.org/wiki/%D0%97%D0%BE%D1%80%D0%B0%D1%86-%D0%9A%D0%B0%D1%80%D0%B5%D1%80',
      },
      en:{
        img: '/assets/images/history/qarahunj.jpg',
        name: 'Carahunge',
        url: 'https://en.wikipedia.org/wiki/Carahunge',
      }
    },
    {
      am:{
        img: '/assets/images/history/garni.jpg',
        name: 'Գառնու հեթանոսական տաճար',
        url: 'https://hy.wikipedia.org/wiki/%D4%B3%D5%A1%D5%BC%D5%B6%D5%B8%D6%82_%D5%B0%D5%A5%D5%A9%D5%A1%D5%B6%D5%B8%D5%BD%D5%A1%D5%AF%D5%A1%D5%B6_%D5%BF%D5%A1%D5%B3%D5%A1%D6%80',
      },
      ru:{
        img: '/assets/images/history/garni.jpg',
        name: 'Гарни (храм)',
        url: 'https://ru.wikipedia.org/wiki/%D0%93%D0%B0%D1%80%D0%BD%D0%B8_(%D1%85%D1%80%D0%B0%D0%BC)',
      },
      en:{
        img: '/assets/images/history/garni.jpg',
        name: 'Temple of Garni',
        url: 'https://en.wikipedia.org/wiki/Temple_of_Garni',
      }
    },
    {
      am:{
        img: '/assets/images/history/mashtots.jpg',
        name: 'Մեսրոպ Մաշտոց',
        url: 'https://hy.wikipedia.org/wiki/%D5%84%D5%A5%D5%BD%D6%80%D5%B8%D5%BA_%D5%84%D5%A1%D5%B7%D5%BF%D5%B8%D6%81',
      },
      ru:{
        img: '/assets/images/history/mashtots.jpg',
        name: 'Месроп Маштоц',
        url: 'https://ru.wikipedia.org/wiki/%D0%9C%D0%B5%D1%81%D1%80%D0%BE%D0%BF_%D0%9C%D0%B0%D1%88%D1%82%D0%BE%D1%86',
      },
      en:{
        img: '/assets/images/history/mashtots.jpg',
        name: 'Mesrop Mashtots',
        url: 'https://en.wikipedia.org/wiki/Mesrop_Mashtots',
      }
    },
    {
      am:{
        img: '/assets/images/history/sardarapat.jpg',
        name: 'Սարդարապատի հուշահամալիր',
        url: 'https://hy.wikipedia.org/wiki/%D5%8D%D5%A1%D6%80%D5%A4%D5%A1%D6%80%D5%A1%D5%BA%D5%A1%D5%BF%D5%AB_%D5%B0%D5%A5%D6%80%D5%B8%D5%BD%D5%A1%D5%B4%D5%A1%D6%80%D5%BF%D5%AB_%D5%B0%D5%B8%D6%82%D5%B7%D5%A1%D5%B0%D5%A1%D5%B4%D5%A1%D5%AC%D5%AB%D6%80',
      },
      ru:{
        img: '/assets/images/history/sardarapat.jpg',
        name: 'Сардарапат',
        url: 'https://ru.wikipedia.org/wiki/%D0%9C%D0%B5%D0%BC%D0%BE%D1%80%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9_%D0%BA%D0%BE%D0%BC%D0%BF%D0%BB%D0%B5%D0%BA%D1%81_%C2%AB%D0%A1%D0%B0%D1%80%D0%B4%D0%B0%D1%80%D0%B0%D0%BF%D0%B0%D1%82%C2%BB',
      },
      en:{
        img: '/assets/images/history/sardarapat.jpg',
        name: 'Sardarapat Memorial',
        url: 'https://en.wikipedia.org/wiki/Sardarapat_Memorial',
      }
    },{
      am:{
        img: '/assets/images/history/qristoneutyun.jpg',
        name: 'Քրիստոնեությունը Հայաստանում',
        url: 'https://hy.wikipedia.org/wiki/%D5%94%D6%80%D5%AB%D5%BD%D5%BF%D5%B8%D5%B6%D5%A5%D5%B8%D6%82%D5%A9%D5%B5%D5%A1%D5%B6_%D5%A8%D5%B6%D5%A4%D5%B8%D6%82%D5%B6%D5%B8%D6%82%D5%B4%D5%A8_%D5%80%D5%A1%D5%B5%D5%A1%D5%BD%D5%BF%D5%A1%D5%B6%D5%B8%D6%82%D5%B4',
      },
      ru:{
        img: '/assets/images/history/qristoneutyun.jpg',
        name: 'Христианство в Армении',
        url: 'https://ru.wikipedia.org/wiki/%D0%A0%D0%B5%D0%BB%D0%B8%D0%B3%D0%B8%D1%8F_%D0%B2_%D0%90%D1%80%D0%BC%D0%B5%D0%BD%D0%B8%D0%B8#:~:text=%D0%A5%D1%80%D0%B8%D1%81%D1%82%D0%B8%D0%B0%D0%BD%D1%81%D1%82%D0%B2%D0%BE%20%D0%BF%D0%BE%D1%8F%D0%B2%D0%B8%D0%BB%D0%BE%D1%81%D1%8C%20%D0%B2%20%D0%90%D1%80%D0%BC%D0%B5%D0%BD%D0%B8%D0%B8%20%D0%B2,%D1%81%D1%87%D0%B8%D1%82%D0%B0%D1%8E%D1%82%D1%81%D1%8F%20%D0%BE%D1%81%D0%BD%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8F%D0%BC%D0%B8%20%D0%90%D1%80%D0%BC%D1%8F%D0%BD%D1%81%D0%BA%D0%BE%D0%B9%20%D0%B0%D0%BF%D0%BE%D1%81%D1%82%D0%BE%D0%BB%D1%8C%D1%81%D0%BA%D0%BE%D0%B9%20%D1%86%D0%B5%D1%80%D0%BA%D0%B2%D0%B8.&text=%D0%A2%D0%B0%D0%BA%D0%B8%D0%BC%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%BC%20%D0%BD%D0%B5%D1%81%D0%BA%D0%BE%D0%BB%D1%8C%D0%BA%D0%BE%20%D0%BC%D0%B5%D0%BD%D0%B5%D0%B5%2095%20%25%20%D0%BD%D0%B0%D1%81%D0%B5%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F%20%D1%81%D1%82%D1%80%D0%B0%D0%BD%D1%8B%20%D0%BE%D1%82%D0%BD%D0%BE%D1%81%D1%8F%D1%82%D1%81%D1%8F%20%D0%BA%20%D1%85%D1%80%D0%B8%D1%81%D1%82%D0%B8%D0%B0%D0%BD%D1%81%D1%82%D0%B2%D1%83.',
      },
      en:{
        img: '/assets/images/history/qristoneutyun.jpg',
        name: 'Christianity in Armenia',
        url: 'https://en.wikipedia.org/wiki/Religion_in_Armenia',
      }
    },{
      am:{
        img: '/assets/images/history/etchmiadzin.jpg',
        name: 'Հայաստանյայց Առաքելական Սբ․ Եկեղեցի',
        url: 'https://hy.wikipedia.org/wiki/%D5%80%D5%A1%D5%B5%D5%A1%D5%BD%D5%BF%D5%A1%D5%B6%D5%B5%D5%A1%D5%B5%D6%81_%D4%B1%D5%BC%D5%A1%D6%84%D5%A5%D5%AC%D5%A1%D5%AF%D5%A1%D5%B6_%D5%8D%D5%B8%D6%82%D6%80%D5%A2_%D4%B5%D5%AF%D5%A5%D5%B2%D5%A5%D6%81%D5%AB',
      },
      ru:{
        img: '/assets/images/history/etchmiadzin.jpg',
        name: 'Армянская апостольская церковь',
        url: 'https://ru.wikipedia.org/wiki/%D0%90%D1%80%D0%BC%D1%8F%D0%BD%D1%81%D0%BA%D0%B0%D1%8F_%D0%B0%D0%BF%D0%BE%D1%81%D1%82%D0%BE%D0%BB%D1%8C%D1%81%D0%BA%D0%B0%D1%8F_%D1%86%D0%B5%D1%80%D0%BA%D0%BE%D0%B2%D1%8C',
      },
      en:{
        img: '/assets/images/history/etchmiadzin.jpg',
        name: 'Armenian Apostolic Church',
        url: 'https://en.wikipedia.org/wiki/Armenian_Apostolic_Church',
      }
    },
  ];

  ngOnInit(): void {}
}
