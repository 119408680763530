import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  lng = new BehaviorSubject<string>('am')

  constructor(public cookieService:CookieService,public translate: TranslateService) {
    translate.addLangs(['en', 'ru', 'am']);
    translate.setDefaultLang('am');
    translate.use('am');
    if(this.cookieService.get('kingdom_lng')){
      translate.use(this.cookieService.get('kingdom_lng'));
      this.lng.next(this.cookieService.get('kingdom_lng'))
    }else{
      this.cookieService.put('kingdom_lng','am')
    }
  }

  get getLanguage() {
    return this.lng.asObservable();
  }


  setLanguage(lng:string){
    this.cookieService.put('kingdom_lng',lng);
    this.translate.use(lng);
    this.lng.next(lng)
  }

}
