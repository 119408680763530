<div class="we-represented page">
  <section class="top-banner">
    <div class="mask"></div>
    <div class="container">
      <h2 class="title">{{ "INTROS.SHOPS" | translate }}</h2>
      <div class="links">
        <a routerLink="/home">{{ "MENU.HOME" | translate }}</a
        ><span><i class="fas fa-chevron-right"></i></span>
        <a>{{ "INTROS.SHOPS" | translate }}</a>
      </div>
    </div>
  </section>
  <section class="content">
    <div class="container mt-4">
      <div class="online-shops mb-4">
        <h2 class="mb-0">{{ "ONLINE_SHOPS" | translate }}</h2>
        <div class="list-body">
          <ul class="shop-list">
            <li>
              <a href="https://www.shoppingian.com/search-for-product/?search-product=armenian+kingdom&search-price-from=&search-price-until=&submit= "><h4 class="name">Shoppingian.com</h4></a>
            </li>
            <li>
              <a href="https://retin.am/index.php?route=product%2Fsearch&search=Armenia%20Kingdom&fbclid=IwAR3HWQNF0xfFP5hl3EDAmpeLVj0EI5V2T55rxBtTF6xywmHXQhhmAGi0C_8"><h4 class="name">Retin.am</h4></a>
            </li>
            <li>
              <a href="https://mylittlearmenia.com/search?q=armenian+kingdom"><h4 class="name">My Little Armenia</h4></a>
            </li>
            <li>
              <a href="https://buyarmenian.com/?dgwt_wcas=1&post_type=product&s=armenian%20kingdom&fbclid=IwAR3bkD0bJ7dy78W-PNvZ7yKkerx_RHl1XlYet9ESbSz5eI4tLXKQQoWmKAY"><h4 class="name">Buy Armenian</h4></a>
            </li>
            <li>
              <a href="https://www.wildberries.ru/brands/armenian-kingdom"><h4 class="name">Wildberries</h4></a>
            </li>
          </ul>
        </div>
      </div>
      <div *ngFor="let item of data[currentLng]" class="mb-3">
        <div class="list-header">
          <img class="flag" [src]="item.icon" />
          <h2 class="mb-0">{{ item.title }}</h2>
        </div>
        <div class="list-body">
          <ul class="shop-list">
            <li *ngFor="let shop of item.list">
              <h4 class="name">{{ shop.name }}</h4>
              <h3 *ngIf="shop.address">
                <i class="fas fa-map-marker-alt"></i> {{ shop.address }}
              </h3>
              <h3 *ngIf="shop.address">
                <i class="fas fa-mobile-alt"></i> {{ shop.address }}
              </h3>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</div>
