import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactsComponent } from './contacts/contacts.component';
import {
  ProductPopupComponent,
  ProductsComponent,
} from './products/products.component';
import { GalleryComponent } from './gallery/gallery.component';
import { HistoryOfArmeniaComponent } from './history-of-armenia/history-of-armenia.component';
import { RouterModule } from '@angular/router';

import { MatCarouselModule } from '@ngmodule/material-carousel';
import { MatButtonModule } from '@angular/material/button';
import { CarouselModule } from 'primeng/carousel';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import {IvyGalleryModule} from 'angular-gallery';
import { WeRepresentedComponent } from './we-represented/we-represented.component';

@NgModule({
  declarations: [
    HomeComponent,
    AboutUsComponent,
    ContactsComponent,
    ProductsComponent,
    GalleryComponent,
    HistoryOfArmeniaComponent,
    ProductPopupComponent,
    WeRepresentedComponent,
  ],
  imports: [
    CommonModule,
    MatCarouselModule.forRoot(),
    MatButtonModule,
    CarouselModule,
    MatFormFieldModule,
    MatInputModule,
    RouterModule,
    MatDialogModule,
    TranslateModule,
    IvyGalleryModule
  ],
})
export class PagesModule {}
