<div class="gallery-page page">
  <section class="top-banner">
    <div class="mask"></div>
    <div class="container">
      <h2 class="title">{{ "INTROS.GALLERY" | translate }}</h2>
      <div class="links">
        <a routerLink="/home">{{ "MENU.HOME" | translate }}</a
        ><span><i class="fas fa-chevron-right"></i></span>
        <a>{{ "INTROS.GALLERY" | translate }}</a>
      </div>
    </div>
  </section>
  <section class="gallery-container mb-4">
    <div class="container">
      <h2 class="title mb-3">{{ "INTROS.GALLERY" | translate }}</h2>
      <div class="row">
        <div class="col-lg-3" *ngFor="let item of pictures; let index = index">
          <div
            class="picture-item mb-3"
            style="background-image: url('{{ item.path }}');"
            (click)="showGallery(index)"
          ></div>
        </div>
      </div>
    </div>
  </section>
  <section class="video-container">
    <div class="container">
      <h2 class="title mb-4">{{ "INTROS.VIDEO" | translate }}</h2>
      <div class="row">
        <div class="col-lg-3">
          <div class="video-item mb-3">
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/QiJMtbSAX50" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
        <div class="col-lg-3">
            <div class="video-item mb-3">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/4e_5uxKIBjM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="video-item mb-3">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/paiq0qzjuq8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="video-item mb-3">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/95NCY6T2RlU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="video-item mb-3">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/2ChAGs8weB8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="video-item mb-3">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/IkVgV39n078" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="video-item mb-3">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/xE65OQjnyPI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="video-item mb-3">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/lRwBcEnLbmQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="video-item mb-3">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/DYC4wuIvY90" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="video-item mb-3">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/OObch7GhLnE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
      </div>
    </div>
  </section>
</div>
