import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  responsiveOptions: Array<any>;
  currentLng:string = 'am'
  constructor(public service:LanguageService) {
    this.responsiveOptions = [
     
      {
        breakpoint: '1440px',
        numVisible: 3,
        numScroll: 3,
      },
      {
        breakpoint: '1024px',
        numVisible: 2,
        numScroll: 2,
      },
      {
        breakpoint: '768px',
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1,
      },
    ];
    this.service.getLanguage.subscribe(res=>{
      this.currentLng = res
    })
  }
 

  slides: Array<any> = [
    'assets/images/top-banner-04.jpg',
    'assets/images/top-banner-05.png',
    'assets/images/top-banner-1.jpg',
  ];

  partners: Array<any> = [
    {logo:"assets/images/partners/wildberries.png",path:"https://www.wildberries.ru/brands/armenian-kingdom?fbclid=IwAR3UMMW7Xa7PBGtL3hkpXS3dC0uwWHrbVFc5yKVKUZP31JjB0iukU46cf90"},
    {logo:"assets/images/partners/byArmenia.jpg",path:"https://buyarmenian.com/?dgwt_wcas=1&post_type=product&s=armenian%20kingdom&fbclid=IwAR1QenN_xevkZtFdNjmzjDhpOpXNSiLPvwgM31fh7zCSOnzGZ02gvqQqfdU"},
    {logo:"assets/images/partners/little-Armenia.png",path:"https://mylittlearmenia.com/search?q=armenian%20kingdom&fbclid=IwAR0_mgUxz9LpnSET16tRxeAruhrftZiAloqmii5mzzhJlljh4dE5Tp3D-KY"},
    {logo:"assets/images/partners/city.jpg",path:"https://www.facebook.com/citysupermarkets/"},
    {logo:"assets/images/partners/bonus.jpg",path:"https://www.facebook.com/BonusSupermarket/"},
    {logo:"assets/images/partners/nor-zovq.png",path:"https://www.facebook.com/NorZovqOfficial/"},
    {logo:"assets/images/partners/nrneni.png",path:"https://www.facebook.com/nrnenigourmethouse/"},
    {logo:"assets/images/partners/azgayin.jpg",path:"https://www.facebook.com/azgayintextile/"},
    {logo:"assets/images/partners/coffe.jpg",path:"https://www.facebook.com/Coffeeology2015/"},
    {logo:"assets/images/partners/shopping.jpg",path:"https://www.shoppingian.com/?fbclid=IwAR0o9kC-IqqU6HTtFkKgOGTzwAVMHaIkX3FGQigqf1dVXzF4yWVR"},
    {logo:"assets/images/partners/levon-lamara.jpg",path:"https://www.facebook.com/LevonLamara/"},
    {logo:"assets/images/partners/retin-am.png",path:"https://retin.am/?fbclid=IwAR0Vx6QTKz5SQxDmDyB93q7hnUAPJljMAuY1_Z3W6ul9QpHymPIYG4tz5Zc"},
  ];

  

  tea = [
    {
      am:{
        img: 'assets/images/products/teas/01.png',
        name: 'ՈՒՐՑ',
        description: 'Ձեզ համար ընտրված բնական այս թեյը պատրաստված է ուրցի ծաղիկներից և տերևներից...',
      },
      ru:{
        img: 'assets/images/products/teas/01.png',
        name: 'ЧАБРЕЦ / ТИМЬЯН',
        description: 'Этот выбранный для вас натуральный чай изготовлен из цветков и листьев чабреца...',
      },
      en:{
        img: 'assets/images/products/teas/01.png',
        name: 'THYME',
        description: 'This natural tea, selected for you, is made from thyme flowers and leaves...',
      }
    },

    {
      am:{
        img: 'assets/images/products/teas/02.png',
        name: 'ՍԵՎ ԹԵՅ՝ ՈՒՐՑՈՎ',
        description:
          'Ձեզ համար ընտրված բնական այս փունջը պատրաստված է ուրցի և ցեյլոնյան սև...',
      },
      ru:{
        img: 'assets/images/products/teas/02.png',
        name: 'ЧЕРНЫЙ ЧАЙ С ЧАБРЕЦОМ/С ТИМЬЯНОМ',
        description:
          'Этот натуральный сбор, выбранный для вас, изготовлен из листьев чабреца...',
      },
      en:{
        img: 'assets/images/products/teas/02.png',
        name: 'BLACK TEA WITH THYME',
        description:
          'This natural bouquet, chosen for you, is made from the leaves of thyme...',
      }
    },
    
    {
      am:{
        img: 'assets/images/products/teas/03.png',
        name: 'ՍԵՎ ԹԵՅ՝ ԴԱՐՉԻՆՈՎ ԵՎ ՄԵԽԱԿՈՎ',
        description:
          'Ձեզ համար ընտրված բնական այս փունջը պատրաստված է սև թեյի տերևներից...',
      },
      ru:{
        img: 'assets/images/products/teas/03.png',
        name: 'ЧЕРНЫЙ ЧАЙ С КОРИЦЕЙ И ГВОЗДИКОЙ',
        description:
          'Этот натуральный букет, выбранный для вас, составлен из черных чайных...',
      },
      en:{
        img: 'assets/images/products/teas/03.png',
        name: 'BLACK TEA WITH CINNAMON AND CLOVES',
        description:
          'This natural bouquet made from black tea leaves, cinnamon...',
      }
    },
    
    {
      am:{
        img: 'assets/images/products/teas/04.png',
        name: 'ՍԵՎ ԹԵՅ՝ ՈՒՐՑՈՎ ԵՎ ՆԱՆԱՅՈՎ',
        description:
          'Ձեզ համար ընտրված բնական այս փունջը պատրաստված է ուրցի, նանայի...',
      },
      ru:{
        img: 'assets/images/products/teas/04.png',
        name: 'ЧЕРНЫЙ ЧАЙ С ЧАБРЕЦОМ И МЯТОЙ',
        description:
          'Этот выбранный для вас природный букет составлен из листьев чабреца, мяты...',
      },
      en:{
        img: 'assets/images/products/teas/04.png',
        name: 'BLACK TEA WITH THYME AND MINT',
        description:
          'This natural bouquet is made from the leaves of thyme, mint and Ceylon...',
      }
    },
    {
      am:{
        img: 'assets/images/products/teas/05.png',
        name: 'ՈՒՐՑ՝ ՆԱՆԱՅՈՎ',
        description:
          'Ձեզ համար ընտրված բնական այս փունջը պատրաստված է ուրցի և նանայի ծաղիկ...',
      },
      ru:{
        img: 'assets/images/products/teas/05.png',
        name: 'ЧАБРЕЦ С МЯТОЙ',
        description:
          'Этот выбранный для вас природный букет составлен из цветов и листьев...',
      },
      en:{
        img: 'assets/images/products/teas/05.png',
        name: 'THYME WITH MINT',
        description:
          'This natural bunch is just for you.It is made from the flowers and...',
        }
    },
    
    {
      am:{
        img: 'assets/images/products/teas/06.png',
        name: 'ՓՈՒՆՋ',
        description:
          'Ձեզ համար ընտրված բնական փունջը պատրաստված է սև թեյի տերևներից, ուրցի և նանայի...',
      },
      ru:{
        img: 'assets/images/products/teas/06.png',
        name: 'БУКЕТ',
        description:
          'Природный сбор, выбранный для вас, изготовлен из черных чайных листьев...',
      },
      en:{
        img: 'assets/images/products/teas/06.png',
        name: 'BUNCH',
        description:
          'This natural bunch(as it is called) is made from tea leaves...',
      }
    },
    {
      am:{
        img: 'assets/images/products/teas/07.png',
        name: 'ՆԱՆԱ',
        description:
          'Ձեզ համար ընտրված բնական խոտաբույսով այս թեյը պատրաստված է նանայի...',
      },
      ru:{
        img: 'assets/images/products/teas/07.png',
        name: 'МЯТА',
        description:
          'Выбранный для вас природный чай, изготовленный из верхних листьев мяты...',
      },
      en:{
        img: 'assets/images/products/teas/07.png',
        name: 'MINT(NANA)',
        description:
          'This natural herbal tea ismade from the top leaves of mint...',
      }
    },
  ]
    

  ngOnInit(): void {}

  playPause(video) {
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  }

  
}
