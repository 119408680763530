import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { HistoryOfArmeniaComponent } from './pages/history-of-armenia/history-of-armenia.component';
import { HomeComponent } from './pages/home/home.component';
import { ProductsComponent } from './pages/products/products.component';
import { WeRepresentedComponent } from './pages/we-represented/we-represented.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'products', component: ProductsComponent,children:[
    {path:'teas',component:ProductsComponent},
    {path:'dried_fruits',component:ProductsComponent},
    {path:'souvenirs',component:ProductsComponent}
  ] },
  { path: 'armenian-history', component: HistoryOfArmeniaComponent },
  { path: 'gallery', component: GalleryComponent },
  { path: 'we-represented', component: WeRepresentedComponent },
  { path: 'contacts', component: ContactsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
