<div class="home-page">
  <section class="top-banner">
    <mat-carousel
      timings="250ms ease-in"
      [autoplay]="true"
      interval="80000"
      maxWidth="100%"
      maxHeight="500"
      proportion="50"
      slides="3"
      [loop]="true"
      [hideArrows]="true"
      [hideIndicators]="false"
      orientation="ltr"
    >
      <mat-carousel-slide
        #matCarouselSlide
        *ngFor="let slide of slides; let i = index"
        [image]="slide"
        [hideOverlay]="false"
        overlayColor="#000000a6"
      >
        <div class="container">
          <div class="leafs"></div>
          <div class="information">
            <div class="title" [innerHTML]="'HOME.TITLE' | translate"></div>
            <div
              class="description"
              [innerHTML]="'HOME.DESCRIPTION' | translate"
            ></div>
            <div class="tools d-flex justify-content-center mt-4">
              <a routerLink="/products/teas" class="d-none d-md-flex">
                <button class="kng-btn">
                  <span>{{ "BUTTONS.MORE" | translate }}</span>
                  <div class="chevron">
                    <i class="fas fa-chevron-right"></i>
                  </div>
                </button>
              </a>
            </div>
          </div>
        </div>
        <div class="social-media">
          <div class="links">
            <a
              target="_blank"
              href="https://www.facebook.com/ArmenianKingdom.am"
              ><i class="fab fa-facebook-f"></i
            ></a>
            <a
              target="_blank"
              href="https://www.instagram.com/armeniankingdom.am/"
              ><i class="fab fa-instagram"></i
            ></a>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UC8expIMKfWEb1iQTlkIW83g"
              ><i class="fab fa-youtube"></i
            ></a>
          </div>
          <div class="text">{{ "FOLLOW_US" | translate }}</div>
        </div>
      </mat-carousel-slide>
    </mat-carousel>
  </section>
  <section class="about-company light-bg">
    <div class="container">
      <h3 class="section-intro">{{ "INTROS.ABOUT" | translate }}</h3>
      <h2 class="section-title">{{ "ABOUT.TITLE" | translate }}</h2>
      <p class="section-description">
        {{ "ABOUT.SECTION_DESCRIPTION" | translate }}
      </p>
      <div class="info-block">
        <div class="company-transmissions row">
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-12 item mb-3">
            <div class="icon">
              <img src="assets/images/icons/icon-1.png" />
            </div>
            <h3 class="title">{{ "ABOUT.STEPS.1.TITLE" | translate }}</h3>
            <p class="description">
              {{ "ABOUT.STEPS.1.DESCRIPTION" | translate }}
            </p>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-12 item mb-3">
            <div class="icon">
              <img src="assets/images/icons/icon-2.png" />
            </div>
            <h3 class="title">{{ "ABOUT.STEPS.2.TITLE" | translate }}</h3>
            <p class="description">
              {{ "ABOUT.STEPS.2.DESCRIPTION" | translate }}
            </p>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-12 item mb-3">
            <div class="icon">
              <img src="assets/images/icons/icon-3.png" />
            </div>
            <h3 class="title">{{ "ABOUT.STEPS.3.TITLE" | translate }}</h3>
            <p class="description">
              {{ "ABOUT.STEPS.3.DESCRIPTION" | translate }}
            </p>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-12 item mb-3">
            <div class="icon">
              <img src="assets/images/icons/icon-4.png" />
            </div>
            <h3 class="title">{{ "ABOUT.STEPS.4.TITLE" | translate }}</h3>
            <p class="description">
              {{ "ABOUT.STEPS.4.DESCRIPTION" | translate }}
            </p>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-12 item mb-3">
            <div class="icon">
              <img src="assets/images/icons/icon-5.png" />
            </div>
            <h3 class="title">{{ "ABOUT.STEPS.5.TITLE" | translate }}</h3>
            <p class="description">
              {{ "ABOUT.STEPS.5.DESCRIPTION" | translate }}
            </p>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-12 item mb-3">
            <div class="icon">
              <img src="assets/images/icons/icon-6.png" />
            </div>
            <h3 class="title">{{ "ABOUT.STEPS.6.TITLE" | translate }}</h3>
            <p class="description">
              {{ "ABOUT.STEPS.6.DESCRIPTION" | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="tools d-flex justify-content-center mt-4">
        <a routerLink="/about-us">
          <button class="kng-dark-btn">
            <span>{{ "BUTTONS.MORE" | translate }}</span>
            <div class="chevron"><i class="fas fa-chevron-right"></i></div>
          </button>
        </a>
      </div>
    </div>
  </section>
  <section class="products dark-bg">
    <div class="mask"></div>
    <div class="container">
      <h3 class="section-intro">{{ "INTROS.PRODUCTS" | translate }}</h3>
      <h2 class="section-title">{{ "PRODUCTS.TITLE" | translate }}</h2>
      <p class="section-description">
        {{ "PRODUCTS.SECTION_DESCRIPTION" | translate }}
        {{ currentLng }}
      </p>

      <p-carousel
        [value]="tea"
        [numVisible]="3"
        [responsiveOptions]="responsiveOptions"
      >
        <ng-template let-tea pTemplate="item">
          <div class="carousel-block">
            <div class="bg">
              <div class="image">
                <img src="{{ tea[currentLng].img }}" />
              </div>
              <div class="info">
                <h3 class="product-title">{{ tea[currentLng].name }}</h3>
                <p class="product-description">
                  {{ tea[currentLng].description }}
                </p>
                <a routerLink="/products/teas">
                  <button class="read-more">
                    <i class="fas fa-chevron-right"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </ng-template>
      </p-carousel>
    </div>
  </section>
  <section class="coming-soon light-bg p-0 pt-4">
    <div class="container">
      <h3 class="section-intro">
        {{ "INTROS.EXPECTED_PRODUCTS" | translate }}
      </h3>
      <h2 class="section-title">{{ "EXPECTED_PRODUCTS.TITLE" | translate }}</h2>
      <p class="section-description">
        {{ "EXPECTED_PRODUCTS.SECTION_DESCRIPTION" | translate }}
      </p>
    </div>
    <div class="row mt-4">
      <div class="col-lg-6 col-sm-12 p-0">
        <img src="assets/images/chay 1170x520.jpg" />
      </div>
      <div class="col-lg-6 col-sm-12 p-0">
        <img src="assets/images/coffee final 1170x520.jpg" />
      </div>
    </div>
  </section>
  <section class="video-section dark-bg">
    <div class="video-bg dried-fruits">
      <div class="mask"></div>
    </div>
    <div class="container text-center">
      <h2 class="section-title mt-4">
        {{ "PRODUCTS.TITLE_DRIED_FRUITS" | translate }}
      </h2>
      <a routerLink="/products/dried_fruits" class="d-none d-md-inline-flex">
        <button class="kng-btn">
          <span>{{ "BUTTONS.MORE" | translate }}</span>
          <div class="chevron">
            <i class="fas fa-chevron-right"></i>
          </div>
        </button>
      </a>
      <div class="video-container">
        <div class="video">
          <div
            class="play"
            [ngClass]="{ 'd-none': !video.paused }"
            (click)="playPause(video2)"
          >
            <i *ngIf="video2.paused" class="fa fa-play"></i>
            <i *ngIf="!video2.paused" class="fa fa-pause"></i>
          </div>
          <video controls="true" #video2 src="/assets/videos/4.mp4"></video>
        </div>
      </div>
    </div>
  </section>

  <section class="partners light-bg">
    <div class="container">
      <h2 class="section-title mt-4">{{ "PARTNERS.TITLE" | translate }}</h2>
      <p-carousel
        [value]="partners"
        [numVisible]="6"
        [responsiveOptions]="responsiveOptions"
      >
        <ng-template let-partner pTemplate="item">
          <div class="partners-item">
            <a [href]="partner.path" target="_blank"
              ><img src="{{ partner.logo }}"
            /></a>
          </div>
        </ng-template>
      </p-carousel>
    </div>
  </section>

  <section class="video-section dark-bg">
    <div class="video-bg">
      <div class="mask"></div>
    </div>
    <div class="container">
      <h2 class="section-title mt-4">{{ "VIDEO.TITLE" | translate }}</h2>
      <p class="description text-center">
        {{ "VIDEO.SECTION_DESCRIPTION" | translate }}
      </p>
      <div class="video-container">
        <div class="video">
          <div
            class="play"
            [ngClass]="{ 'd-none': !video.paused }"
            (click)="playPause(video)"
          >
            <i *ngIf="video.paused" class="fa fa-play"></i>
            <i *ngIf="!video.paused" class="fa fa-pause"></i>
          </div>
          <video #video src="/assets/videos/3.mp4"></video>
        </div>
      </div>
    </div>
  </section>

  <section class="contacts mt-4">
    <div class="container">
      <div class="contacts-container">
        <div class="item">
          <div class="icon">
            <i class="fas fa-phone-alt"></i>
          </div>
          <div class="text">+374 93 71-21-17</div>
        </div>
        <div class="item">
          <div class="icon">
            <i class="fas fa-envelope"></i>
          </div>
          <div class="text">armeniankingdom@mail.ru</div>
        </div>
        <div class="item">
          <div class="icon">
            <i class="fas fa-map-marker-alt"></i>
          </div>
          <div class="text">{{ "CONTACTS.ADDRESS" | translate }}</div>
        </div>
      </div>
      <div class="form-container mt-4">
        <div class="row">
          <div class="col-lg-5 col-sm-12 mb-3">
            <h2 class="section-title">{{ "CONTACTS.TITLE" | translate }}</h2>
            <form>
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>{{
                  "CONTACTS.FORM.FULL_NAME" | translate
                }}</mat-label>
                <input matInput />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>{{ "CONTACTS.FORM.EMAIL" | translate }}</mat-label>
                <input matInput />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>{{ "CONTACTS.FORM.MESSAGE" | translate }}</mat-label>
                <textarea rows="5" matInput></textarea>
              </mat-form-field>
              <div class="d-flex justify-content-end">
                <button class="kng-btn">
                  <i class="fas fa-paper-plane"></i
                  ><span>{{ "BUTTONS.SEND" | translate }}</span>
                </button>
              </div>
            </form>
          </div>
          <div class="col-lg-7 col-sm-12 mb-3">
            <div style="position: relative; overflow: hidden">
              <a
                href="https://yandex.ru/maps/10262/yerevan/?utm_medium=mapframe&utm_source=maps"
                style="
                  color: #eee;
                  font-size: 12px;
                  position: absolute;
                  top: 0px;
                "
                >Ереван</a
              ><a
                href="https://yandex.ru/maps/10262/yerevan/geo/1513618756/?ll=44.489250%2C40.214779&utm_medium=mapframe&utm_source=maps&z=17.34"
                style="
                  color: #eee;
                  font-size: 12px;
                  position: absolute;
                  top: 14px;
                "
                >Улица Сасна Црери — Яндекс.Карты</a
              ><iframe
                src="https://yandex.ru/map-widget/v1/-/CCUiUQSklC"
                width="100%"
                height="400"
                frameborder="1"
                allowfullscreen="true"
                style="position: relative"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
