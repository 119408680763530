<div class="contacts-page page">
    <section class="top-banner">
        <div class="mask"></div>
        <div class="container">
            <h2 class="title">{{'CONTACTS.TITLE'|translate}}</h2>
            <div class="links">
                <a routerLink="/home">{{'MENU.HOME'|translate}}</a
        ><span><i class="fas fa-chevron-right"></i></span>
        <a>{{'CONTACTS.TITLE'|translate}}</a>
            </div>
        </div>
    </section>
    <section class="contacts light-bg">
        <div class="container">
            <h2 class="section-title">{{'CONTACTS.TITLE'|translate}}</h2>
            <p class="description">
                {{'CONTACTS.DESCRIPTION'|translate}}
            </p>
            <div class="contacts-container">
                <div class="item">
                    <div class="icon">
                        <i class="fas fa-phone-alt"></i>
                    </div>
                    <div class="text">+374 93 71-21-17</div>
                </div>
                <div class="item">
                    <div class="icon">
                        <i class="fas fa-envelope"></i>
                    </div>
                    <div class="text">armeniankingdom@mail.ru</div>
                </div>
                <div class="item">
                    <div class="icon">
                        <i class="fas fa-map-marker-alt"></i>
                    </div>
                    <div class="text">{{'CONTACTS.ADDRESS'|translate}}</div>
                </div>
            </div>
            <div class="form-container mt-4">
                <div class="row">
                    <div class="col-lg-5 col-sm-12 mb-3">
                        <form>
                            <mat-form-field class="full-width" appearance="fill">
                                <mat-label>{{'CONTACTS.FORM.FULL_NAME'|translate}}</mat-label>
                                <input matInput />
                            </mat-form-field>
                            <mat-form-field class="full-width" appearance="fill">
                                <mat-label>{{'CONTACTS.FORM.EMAIL'|translate}}</mat-label>
                                <input matInput />
                            </mat-form-field>
                            <mat-form-field class="full-width" appearance="fill">
                                <mat-label>{{'CONTACTS.FORM.MESSAGE'|translate}}</mat-label>
                                <textarea rows="5" matInput></textarea>
                            </mat-form-field>
                            <div class="d-flex justify-content-end">
                                <button class="kng-btn">
                  <i class="fas fa-paper-plane"></i><span >{{'BUTTONS.SEND'|translate}}</span>
                </button>
                            </div>
                        </form>
                    </div>
                    <div class="col-lg-7 col-sm-12">
                        <div style="position: relative; overflow: hidden">
                            <a href="https://yandex.ru/maps/10262/yerevan/?utm_medium=mapframe&utm_source=maps" style="
                  color: #eee;
                  font-size: 12px;
                  position: absolute;
                  top: 0px;
                ">Ереван</a
              ><a
                href="https://yandex.ru/maps/10262/yerevan/geo/1513618756/?ll=44.489250%2C40.214779&utm_medium=mapframe&utm_source=maps&z=17.34"
                style="
                  color: #eee;
                  font-size: 12px;
                  position: absolute;
                  top: 14px;
                "
                >Улица Сасна Црери — Яндекс.Карты</a
              ><iframe
                src="https://yandex.ru/map-widget/v1/-/CCUiUQSklC"
                width="100%"
                height="400"
                frameborder="1"
                allowfullscreen="true"
                style="position: relative"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>