<div class="products-page page">
  <section class="top-banner">
    <div class="mask"></div>
    <div class="container">
      <h2 class="title">{{ "INTROS.PRODUCTS" | translate }}</h2>
      <div class="links">
        <a routerLink="/home">{{ "MENU.HOME" | translate }}</a
        ><span><i class="fas fa-chevron-right"></i></span>
        <a>{{ "MENU.PRODUCTS" | translate }}</a>
      </div>
    </div>
  </section>
  <section class="products light-bg">
    <div class="container">
      <div *ngIf="router.url == '/products/teas'" >
        <h3 class="section-intro">{{ "INTROS.TEAS" | translate }}</h3>
        <h2 class="section-title">{{ "PRODUCTS.TITLE_TEA" | translate }}</h2>
        <div
          class="description text-center"
          [innerHTML]="'PRODUCTS.DESCRIPTION_TEA' | translate"
        ></div>
      </div>
      <div *ngIf="router.url == '/products/dried_fruits'">
        <h3 class="section-intro">{{ "INTROS.DRIED_FRUITS" | translate }}</h3>
        <h2 class="section-title">
          {{ "PRODUCTS.TITLE_DRIED_FRUITS" | translate }}
        </h2>
        <div
          class="description text-center"
          [innerHTML]="'PRODUCTS.DESCRIPTION_DRIED_FRUITS' | translate"
        ></div>
      </div>
      <div *ngIf="router.url == '/products/souvenirs'">
        <h3 class="section-intro">{{ "INTROS.SOUVENIRS" | translate }}</h3>
        <h2 class="section-title">
          {{ "PRODUCTS.TITLE_SOUVENIRS" | translate }}
        </h2>
        <div
          class="description text-center"
          [innerHTML]="'PRODUCTS.DESCRIPTION_SOUVENIRS' | translate"
        ></div>
      </div>

      <div class="products-row" *ngIf="router.url == '/products/teas'">
        <div class="item mb-3" *ngFor="let product of teas">
          <div class="product-content">
            <div class="image">
              <img src="{{ product[currentLng].img }}" />
            </div>
            <h3 class="name">{{ product[currentLng].name }}</h3>
            <div class="top-layer">
              <h3 class="name">{{ product[currentLng].name }}</h3>
              <button
                class="kng-btn"
                (click)="openProductDialog(product[currentLng])"
              >
                <span>{{ "BUTTONS.MORE" | translate }}</span>
                <div class="chevron"><i class="fas fa-chevron-right"></i></div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="products-row" *ngIf="router.url == '/products/dried_fruits'">
        <div class="item mb-3" *ngFor="let product of driedFruits">
          <div class="product-content">
            <div class="image">
              <img src="{{ product[currentLng].img }}" />
            </div>
            <h3 class="name">{{ product[currentLng].name }}</h3>
            <div class="top-layer">
              <h3 class="name">{{ product[currentLng].name }}</h3>
              <button
                class="kng-btn"
                (click)="openProductDialog(product[currentLng])"
              >
                <span>{{ "BUTTONS.MORE" | translate }}</span>
                <div class="chevron"><i class="fas fa-chevron-right"></i></div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="products-row" *ngIf="router.url == '/products/souvenirs'">
        <div class="item mb-3" *ngFor="let product of souvenirs">
          <div class="product-content">
            <div class="image">
              <img src="{{ product[currentLng].img }}" />
            </div>
            <h3 class="name">{{ product[currentLng].name }}</h3>
            <div class="top-layer">
              <h3 class="name">{{ product[currentLng].name }}</h3>
              <button
                class="kng-btn"
                (click)="openProductDialog(product[currentLng])"
              >
                <span>{{ "BUTTONS.MORE" | translate }}</span>
                <div class="chevron"><i class="fas fa-chevron-right"></i></div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
