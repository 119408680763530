import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  lng: Array<any> = [
    { lng: 'am', name: 'Հայերեն' },
    { lng: 'ru', name: 'Русский' },
    { lng: 'en', name: 'English' },
  ];
  selectedLang: any = this.lng[0];

  constructor(private service:LanguageService) {
    let currentLng:string
    this.service.getLanguage.subscribe(res=>{
      currentLng = res
    })
    this.selectedLang = this.lng.find(x=>x.lng == currentLng)
  }

  ngOnInit(): void {}

  switchLanguage(event: any) {
    let lng = event.value.lng;
    this.service.setLanguage(lng)
    // this.translate.use(lng);
    // this.cookieService.put('kingdom_lng',lng)
  }
}
