<div class="header">
    <div class="container">
        <div class="logo">
            <img src="assets/images/logo.png" />
        </div>
        <div class="menu d-none d-lg-flex align-items-stretch">
            <a routerLink="home" routerLinkActive="active-menu" class="item">{{'MENU.HOME'|translate}}</a>
            <a routerLink="about-us" routerLinkActive="active-menu" class="item">{{'MENU.ABOUT'|translate}}</a>
            <!-- <a routerLink="products" routerLinkActive="active-menu" class="item" >{{'MENU.PRODUCTS'|translate}}</a> -->
            <button mat-button class="item" [matMenuTriggerFor]="products">{{'MENU.PRODUCTS'|translate}}</button>
            <mat-menu #products="matMenu">
                <a routerLink="products/teas" routerLinkActive="active-menu"><button mat-menu-item>{{'MENU.TEAS'|translate}}</button></a>
                <a routerLink="products/dried_fruits" routerLinkActive="active-menu"><button mat-menu-item>{{'MENU.DRIED_FRUITS'|translate}}</button></a>
                <a routerLink="products/souvenirs" routerLinkActive="active-menu"><button mat-menu-item>{{'MENU.SOUVENIRS'|translate}}</button></a>
            </mat-menu>
            <a routerLink="armenian-history" routerLinkActive="active-menu" class="item">{{'MENU.HISTORY'|translate}}</a>
            <a routerLink="gallery" routerLinkActive="active-menu" class="item">{{'MENU.GALLERY'|translate}}</a>
            <a routerLink="we-represented" routerLinkActive="active-menu" class="item" >{{'MENU.SHOPS'|translate}}</a>
            <a routerLink="contacts" routerLinkActive="active-menu" class="item">{{'MENU.CONTACTS'|translate}}</a>
            <div class="language-flag">
                <p-dropdown [options]="lng" [(ngModel)]="selectedLang" (onChange)="switchLanguage($event)">
                    <ng-template pTemplate="selectedItem">
                        <ng-container *ngIf="selectedLang">
                            <img style="width: 20px;margin-right: .75rem;" src="assets/images/flags/{{selectedLang.lng}}.svg" /><span>{{selectedLang.name}}</span>
                        </ng-container>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                        <ng-container>
                            <img style="width: 20px;margin-right: .75rem;" src="assets/images/flags/{{item.lng}}.svg" /><span>{{item.name}}</span>
                        </ng-container>
                    </ng-template>
                </p-dropdown>
            </div>
        </div>
        <div class="mobile d-lg-none d-md-flex">
            <div class="mobile_menu">
                <i class="fas fa-bars" [matMenuTriggerFor]="menu"></i>
                <mat-menu #menu="matMenu">
                    <a routerLink="home" class="item"><button routerLinkActive="active-menu" mat-menu-item >{{'MENU.HOME'|translate}}</button></a>
                    <a routerLink="about-us" class="item"><button routerLinkActive="active-menu" mat-menu-item >{{'MENU.ABOUT'|translate}}</button></a>
                    <a routerLink="products" class="item"><button routerLinkActive="active-menu" mat-menu-item >{{'MENU.PRODUCTS'|translate}}</button></a>
                    <a routerLink="armenian-history" class="item"><button routerLinkActive="active-menu" mat-menu-item >{{'MENU.HISTORY'|translate}}</button></a>
                    <a routerLink="gallery" class="item"><button routerLinkActive="active-menu" mat-menu-item >{{'MENU.GALLERY'|translate}} </button></a>
                    <a routerLink="contacts" class="item"><button routerLinkActive="active-menu" mat-menu-item >{{'MENU.CONTACTS'|translate}}</button></a>
                </mat-menu>
            </div>

            <div class="language-flag">
                <p-dropdown [options]="lng" [(ngModel)]="selectedLang" (onChange)="switchLanguage($event)">
                    <ng-template pTemplate="selectedItem">
                        <ng-container *ngIf="selectedLang">
                            <img style="width: 20px;margin-right: .75rem;" src="assets/images/flags/{{selectedLang.lng}}.svg" /><span>{{selectedLang.name}}</span>
                        </ng-container>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                        <ng-container>
                            <img style="width: 20px;margin-right: .75rem;" src="assets/images/flags/{{item.lng}}.svg" /><span>{{item.name}}</span>
                        </ng-container>
                    </ng-template>
                </p-dropdown>
            </div>
        </div>
    </div>
</div>