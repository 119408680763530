<div class="history-page page">
    <section class="top-banner">
        <div class="mask"></div>
        <div class="container">
            <h2 class="title">{{'INTROS.HISTORY' |translate}}</h2>
            <div class="links">
                <a routerLink="/home">{{'MENU.HOME' |translate}}</a
        ><span><i class="fas fa-chevron-right"></i></span>
        <a>{{'INTROS.HISTORY' |translate}}</a>
            </div>
        </div>
    </section>
    <section class="history-container">
        <div class="container">
            <div class="row">
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-4" *ngFor="let item of historys">
                    <a href="{{ item[currentLng].url }}" target="_blank" rel="noopener noreferrer">
                        <div class="item" style="background-image: url('{{ item[currentLng].img }}');">
                            <div class="mask"></div>
                            <h2 class="name">{{ item[currentLng].name }}</h2>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </section>
</div>