<div class="dialog-header">
    <h2 mat-dialog-title class="m-0 title" >{{ product.name }}</h2>
    <i class="fas fa-times" (click)="closeModal()"></i>
</div>
<div class="dialog-body">
    <div class="image">
        <img src="{{ product.img }}" />
    </div>
    <div class="description">
        <p *ngIf="product.description">
            <span class="label" >{{'DESCRIPTION'|translate}} </span><span [innerHTML]="product.description"></span>
        </p>
        <p *ngIf="product.preparation">
            <span class="label" >{{'PREPARATION'|translate}} </span
      ><span [innerHTML]="product.preparation" ></span>
        </p>
        <p *ngIf="product.storage_method">
            <span class="label" >{{'METHOD'|translate}} </span
      ><span [innerHTML]="product.storage_method"></span>
        </p>
    </div>
</div>