import { Component, OnInit } from '@angular/core';
import { Gallery } from 'angular-gallery';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit {
  constructor(private gallery: Gallery) {}

  pictures: Array<any> = [
    { path: 'assets/images/gallery/1.jpg' },
    { path: 'assets/images/gallery/2.jpg' },
    { path: 'assets/images/gallery/3.jpg' },
    { path: 'assets/images/gallery/4.jpg' },
    { path: 'assets/images/gallery/5.png' },
    { path: 'assets/images/gallery/6.jpg' },
    { path: 'assets/images/gallery/7.jpg' },
    { path: 'assets/images/gallery/8.jpg' },
    { path: 'assets/images/gallery/9.jpg' },
    { path: 'assets/images/gallery/10.jpg' },
    { path: 'assets/images/gallery/11.jpg' },
    { path: 'assets/images/gallery/12.jpg' },
    { path: 'assets/images/gallery/13.jpg' },
    { path: 'assets/images/gallery/14.jpg' },
    { path: 'assets/images/gallery/15.jpg' },
    { path: 'assets/images/gallery/16.jpg' },
    { path: 'assets/images/gallery/17.jpg' },
    { path: 'assets/images/gallery/18.jpg' },
    { path: 'assets/images/gallery/19.jpg' },
    { path: 'assets/images/gallery/20.jpg' },
    { path: 'assets/images/gallery/21.jpg' },
    { path: 'assets/images/gallery/22.jpg' },
    { path: 'assets/images/gallery/23.jpg' },
    { path: 'assets/images/gallery/24.jpg' },
    { path: 'assets/images/gallery/25.jpg' },
    { path: 'assets/images/gallery/26.jpg' },
    { path: 'assets/images/gallery/27.jpg' },
    { path: 'assets/images/gallery/28.jpg' },
    { path: 'assets/images/gallery/29.png' },
    { path: 'assets/images/gallery/30.png' },
    { path: 'assets/images/gallery/31.jpg' },
    { path: 'assets/images/gallery/32.jpg' },
    { path: 'assets/images/gallery/33.png' },
    { path: 'assets/images/gallery/34.jpg' },
    { path: 'assets/images/gallery/35.jpg' },
  ];

  videos: Array<any> = [
    { path: 'https://www.youtube.com/embed/paiq0qzjuq8' },
    // { path: 'https://www.youtube.com/watch?v=4e_5uxKIBjM' },
    // { path: 'https://www.youtube.com/watch?v=wiuAiKE7vVs' },
    // { path: 'https://www.youtube.com/watch?v=paiq0qzjuq8' },
    // { path: '' },
  ];

  ngOnInit(): void {}

  showGallery(index: number) {
    let prop = {
      images: [
        { path: 'assets/images/gallery/1.jpg' },
        { path: 'assets/images/gallery/2.jpg' },
        { path: 'assets/images/gallery/3.jpg' },
        { path: 'assets/images/gallery/4.jpg' },
        { path: 'assets/images/gallery/5.png' },
        { path: 'assets/images/gallery/6.jpg' },
        { path: 'assets/images/gallery/7.jpg' },
        { path: 'assets/images/gallery/8.jpg' },
        { path: 'assets/images/gallery/9.jpg' },
        { path: 'assets/images/gallery/10.jpg' },
        { path: 'assets/images/gallery/11.jpg' },
        { path: 'assets/images/gallery/12.jpg' },
        { path: 'assets/images/gallery/13.jpg' },
        { path: 'assets/images/gallery/14.jpg' },
        { path: 'assets/images/gallery/15.jpg' },
        { path: 'assets/images/gallery/16.jpg' },
        { path: 'assets/images/gallery/17.jpg' },
        { path: 'assets/images/gallery/18.jpg' },
        { path: 'assets/images/gallery/19.jpg' },
        { path: 'assets/images/gallery/20.jpg' },
        { path: 'assets/images/gallery/21.jpg' },
        { path: 'assets/images/gallery/22.jpg' },
        { path: 'assets/images/gallery/23.jpg' },
        { path: 'assets/images/gallery/24.jpg' },
        { path: 'assets/images/gallery/25.jpg' },
        { path: 'assets/images/gallery/26.jpg' },
        { path: 'assets/images/gallery/27.jpg' },
        { path: 'assets/images/gallery/28.jpg' },
        { path: 'assets/images/gallery/29.png' },
        { path: 'assets/images/gallery/30.png' },
        { path: 'assets/images/gallery/31.jpg' },
        { path: 'assets/images/gallery/32.jpg' },
        { path: 'assets/images/gallery/33.png' },
        { path: 'assets/images/gallery/34.jpg' },
        { path: 'assets/images/gallery/35.jpg' },
      ],
      index,
    };
    this.gallery.load(prop);
  }

}
