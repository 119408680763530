import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { LanguageService } from 'src/app/services/language.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  currentLng:string = 'am'
  constructor(public dialog: MatDialog,private service:LanguageService,public router:Router) {
    this.service.getLanguage.subscribe(res=>{
      this.currentLng = res;
    })
  }

  
  teas:Array<any>=[
      {
        am:{
          img: 'assets/images/products/teas/01.png',
          name: 'ՈՒՐՑ',
          short: 'Ձեզ համար ընտրված բնական այս թեյը պատրաստված է ուրցի ծաղիկներից և տերևներից...',
          description:'Ձեզ համար ընտրված բնական այս թեյը պատրաստված է ուրցի ծաղիկներից և տերևներից, մշակված է առանց քիմիական միջամտության, ցանկալի է իր բույրով և համով:',
          preparation:'200 մլ. տարողությամբ գավաթի համար անհրաժեշտ է ½ թեյի գդալ: Թրմել 3-5 րոպե:',
          storage_method:'Պահել չոր տեղում 20 ° С-ից ոչ բարձր ջերմաստիճանում: Պահպանման ժամկետը` 2 տարի:'
        },
        ru:{
          img: 'assets/images/products/teas/01.png',
          name: 'ЧАБРЕЦ / ТИМЬЯН',
          short: 'Этот выбранный для вас натуральный чай изготовлен из цветков и листьев чабреца...',
          description:'Этотвыбранный для васнатуральный чай изготовлен из цветков и листьев чабреца, обработан без химического вмешательства, привлекает ароматом и вкусом.',
          preparation:'На чашку емкостью 200 мл. необходимо ½ чайной ложки. Настаивать 3-5 минут.',
          storage_method:'Хранить в сухом месте при температуре не выше 20 ° С. Срок хранения - 2 года.'
        },
        en:{
          img: 'assets/images/products/teas/01.png',
          name: 'THYME',
          short: 'This natural tea, selected for you, is made from thyme flowers and leaves...',
          description:'This natural tea, selected for you, is made from thyme flowers and leaves, without chemical intervention. It is desirable for its aroma and taste.',
          preparation:'For a 200 ml. cup we need ½ teaspoon of herb. Infuse for 3-5 minutes.',
          storage_method:'Keep in a dry place at temperature not above 20 ° С. Storage is 2 years.'
        }
      },
      {
        am:{
          img: 'assets/images/products/teas/02.png',
          name: 'ՍԵՎ ԹԵՅ՝ ՈՒՐՑՈՎ',
          short:'Ձեզ համար ընտրված բնական այս փունջը պատրաստված է ուրցի և ցեյլոնյան սև...',
          description:'Ձեզ համար ընտրված բնական այս փունջը պատրաստված է ուրցի և ցեյլոնյան սև թեյի տերևներից, մշակված է առանց քիմիական միջամտության, խիստ ախորժելի է թե համով, թե բույրով:',
          preparation:'200 մլ. տարողությամբ գավաթի համար անհրաժեշտ է ½ թեյի գդալ: Թրմել 3-5 րոպե:',
          storage_method:'Պահել չոր տեղում 20 ° С-ից ոչ բարձր ջերմաստիճանում: Պահպանման ժամկետը` 2 տարի:'
        },
        ru:{
          img: 'assets/images/products/teas/02.png',
          name: 'ЧЕРНЫЙ ЧАЙ С ЧАБРЕЦОМ/С ТИМЬЯНОМ',
          short:'Этот натуральный сбор, выбранный для вас, изготовлен из листьев чабреца...',
          description:'Этот натуральный сбор, выбранный для вас, изготовлен из листьев чабреца и черного цейлонского чая, разработан без химического вмешательства, имеет изысканный вкус и аромат.',
          preparation:'На чашку емкостью 200 мл. необходимо ½ чайной ложки. Настаивать 3-5 минут.',
          storage_method:'Хранить в сухом месте при температуре не выше 20 ° С. Срок хранения - 2 года.'
        },
        en:{
          img: 'assets/images/products/teas/02.png',
          name: 'BLACK TEA WITH THYME',
          description:'This natural bouquet, chosen for you, is made from the leaves of thyme and Ceylon black tea, without chemical intervention. It is very tasty and odorous.',
          preparation:'For a 200 ml. cup we need ½ teaspoon of herb. Infuse for 3-5 minutes.',
          storage_method:'Keep in a dry place at temperature not above 20 ° С. Storage is 2 years.',
        }
      },
      {
        am:{
          img: 'assets/images/products/teas/03.png',
          name: 'ՍԵՎ ԹԵՅ՝ ԴԱՐՉԻՆՈՎ ԵՎ ՄԵԽԱԿՈՎ',
          short:'Ձեզ համար ընտրված բնական այս փունջը պատրաստված է սև թեյի տերևներից...',
          description:'Ձեզ համար ընտրված բնական այս փունջը պատրաստված է սև թեյի տերևներից, դարչինից և մեխակից, մշակված է` ներգրավելով միայն մարդու աշխատանքը. Առանց քիմիական միջամտության: Գրավիչ է տեսքով, ախորժելի` համով ու հոտով:',
          preparation:'200 մլ. տարողությամբ գավաթի համար անհրաժեշտ է ½ թեյի գդալ: Թրմել 3-5 րոպե:',
          storage_method:'Պահել չոր տեղում 20 ° С-ից ոչ բարձր ջերմաստիճանում: Պահպանման ժամկետը` 2 տարի:'
        },
        ru:{
          img: 'assets/images/products/teas/03.png',
          name: 'ЧЕРНЫЙ ЧАЙ С КОРИЦЕЙ И ГВОЗДИКОЙ',
          short:'Этот натуральный букет, выбранный для вас, составлен из черных чайных...',
          description:'Этот натуральный букет, выбранный для вас, составлен из черных чайных листьев с корицей и гвоздикой, получен исключительно в результате человеческого труда, без химического вмешательства. Привлекателен внешне, обладает приятным вкусом и запахом.',
          preparation:'На чашку емкостью 200 мл. необходимо ½ чайной ложки. Настаивать 3-5 минут.',
          storage_method:'Хранить в сухом месте при температуре не выше 20 ° С. Срок хранения - 2 года.'
        },
        en:{
          img: 'assets/images/products/teas/03.png',
          name: 'BLACK TEA WITH CINNAMON AND CLOVES',
          short:'This natural bouquet made from black tea leaves, cinnamon...',
          description:'This natural bouquet made from black tea leaves, cinnamon and cloves, involve only human work, with no chemicals. It is attractive in appearance, appetizing with unique taste and smell.',
          preparation:'For a 200 ml. cup we need ½ teaspoon of herb. Infuse for 3-5 minutes.',
          storage_method:'Keep in a dry place at temperature not above 20 ° С. Storage is 2 years.',
        }
      },
      
      {
        am:{
          img: 'assets/images/products/teas/04.png',
          name: 'ՍԵՎ ԹԵՅ՝ ՈՒՐՑՈՎ ԵՎ ՆԱՆԱՅՈՎ',
          short:'Ձեզ համար ընտրված բնական այս փունջը պատրաստված է ուրցի, նանայի...',
          description:'Ձեզ համար ընտրված բնական այս փունջը պատրաստված է ուրցի, նանայի և ցեյլոնյան սև թեյի տերևներից, մշակված է առանց քիմիական միջամտության, ցանկալի է անզուգական բույրով ու համով, գրավիչ`տեսքով:',
          preparation:'200 մլ. տարողությամբ գավաթի համար անհրաժեշտ է ½ թեյի գդալ: Թրմել 3-5 րոպե:',
          storage_method:'Պահել չոր տեղում 20 ° С-ից ոչ բարձր ջերմաստիճանում: Պահպանման ժամկետը` 2 տարի:'
        },
        ru:{
          img: 'assets/images/products/teas/04.png',
          name: 'ЧЕРНЫЙ ЧАЙ С ЧАБРЕЦОМ И МЯТОЙ',
          short:'Этот выбранный для вас природный букет составлен из листьев чабреца, мяты...',
          description:'Этот выбранный для вас природный букет составлен из листьев чабреца, мяты и цейлонского черного чая, без химического вмешательства,привлекает непревзойденным вкусом, ароматом, притягательным внешним видом.',
          preparation:'На чашку емкостью 200 мл. необходимо ½ чайной ложки. Настаивать 3-5 минут',
          storage_method:'Хранить в сухом месте при температуре не выше 20 ° С. Срок хранения - 2 года.'
        },
        en:{
          img: 'assets/images/products/teas/04.png',
          name: 'BLACK TEA WITH THYME AND MINT',
          short:'This natural bouquet is made from the leaves of thyme, mint and Ceylon...',
          description:'This natural bouquet is made from the leaves of thyme, mint and Ceylon black tea with no chemicals. It has incomparable aroma and taste and is attractive in appearance.',
          preparation:'For a 200 ml. cup we need ½ teaspoon of herb. Infuse for 3-5 minutes.',
          storage_method:'Keep in a dry place at temperature not above 20 ° С. Storage is 2 years.',
        }
      },
      {
        am:{
          img: 'assets/images/products/teas/05.png',
          name: 'ՈՒՐՑ՝ ՆԱՆԱՅՈՎ',
          short:'Ձեզ համար ընտրված բնական այս փունջը պատրաստված է ուրցի և նանայի ծաղիկ...',
          description:'Ձեզ համար ընտրված բնական այս փունջը պատրաստված է ուրցի և նանայի ծաղիկներից և տերևներից, մշակված է` ներգրավելով միայն մարդու աշխատանքը, ախորժելի է թե իր բույրով, թե իր անզուգական համով:',
          preparation:'200 մլ. տարողությամբ գավաթի համար անհրաժեշտ է ½ թեյի գդալ: Թրմել 3-5 րոպե:',
          storage_method:'Պահել չոր տեղում 20 ° С-ից ոչ բարձր ջերմաստիճանում: Պահպանման ժամկետը` 2 տարի:'
        },
        ru:{
          img: 'assets/images/products/teas/05.png',
          name: 'ЧАБРЕЦ С МЯТОЙ',
          short:'Этот выбранный для вас природный букет составлен из цветов и листьев...',
          description:'Этот выбранный для вас природный букет составлен из цветов и листьев чабреца и мяты. Он разработан без химического вмешательства, привлекает ароматом и неповторимым вкусом.',
          preparation:'На чашку емкостью 200 мл. необходимо ½ чайной ложки. Настаивать 3-5 минут',
          storage_method:'Хранить в сухом месте при температуре не выше 20 ° С. Срок хранения - 2 года.'
        },
        en:{
          img: 'assets/images/products/teas/05.png',
          name: 'THYME WITH MINT',
          short:'This natural bunch is just for you.It is made from the flowers and...',
          description:'This natural bunch is just for you.It is made from the flowers and leaves of thyme and mint(nana). It involves only human work and has delicious aroma and unique taste.',
          preparation:'For a 200 ml. cup we need ½ teaspoon of herb. Infuse for 3-5 minutes.',
          storage_method:'Keep in a dry place at temperature not above 20 ° С. Storage is 2 years.',
        }
      },
      
      {
        am:{
          img: 'assets/images/products/teas/06.png',
          name: 'ՓՈՒՆՋ',
          short:'Ձեզ համար ընտրված բնական փունջը պատրաստված է սև թեյի տերևներից, ուրցի և նանայի...',
          description:'Ձեզ համար ընտրված բնական փունջը պատրաստված է սև թեյի տերևներից, ուրցի և նանայի ծաղիկներից, դարչինից և մեխակից, մշակված է առանց քիմիական միջամտության, խիստ ախորժելի է թե իր համով, թե իր բույրով ու տեսքով:',
          preparation:'200 մլ. տարողությամբ գավաթի համար անհրաժեշտ է ½ թեյի գդալ: Թրմել 3-5 րոպե:',
          storage_method:'Պահել չոր տեղում 20 ° С-ից ոչ բարձր ջերմաստիճանում: Պահպանման ժամկետը` 2 տարի:'
        },
        ru:{
          img: 'assets/images/products/teas/06.png',
          name: 'БУКЕТ',
          short:'Природный сбор, выбранный для вас, изготовлен из черных чайных листьев...',
          description:'Природный сбор, выбранный для вас, изготовлен из черных чайных листьев, из цветов и листьев чабреца и мяты, с корицей и гвоздикой, без какого-либо химического вмешательства, привлекает своим изысканным вкусом, ароматом и внешним видом.',
          preparation:'На чашку емкостью 200 мл. необходимо ½ чайной ложки. Настаивать 3-5 минут.',
          storage_method:'Хранить в сухом месте при температуре не выше 20 ° С. Срок хранения - 2 года.'
        },
        en:{
          img: 'assets/images/products/teas/06.png',
          name: 'BUNCH',
          short:'This natural bunch(as it is called) is made from tea leaves...',
          description:'This natural bunch(as it is called) is made from tea leaves, thyme and mint flowers, cinnamon and cloves without any chemicals, It is very tasty and appetizing.',
          preparation:'For a 200 ml. cup we need ½ teaspoon of herb. Infuse for 3-5 minutes.',
          storage_method:'Keep in a dry place at temperature not above 20 ° С. Storage is 2 years.',
        }
      },
      {
        am:{
          img: 'assets/images/products/teas/07.png',
          name: 'ՆԱՆԱ',
          short:'Ձեզ համար ընտրված բնական խոտաբույսով այս թեյը պատրաստված է նանայի...',
          description:'Ձեզ համար ընտրված բնական խոտաբույսով այս թեյը պատրաստված է նանայի վերին տերևներից, մշակված է առանց քիմիական միջամտության, ունի հաճելի բուրմունք, ցանկալի է իր համով, գրավիչ` տեսքով:',
          preparation:'200 մլ. տարողությամբ գավաթի համար անհրաժեշտ է ½ թեյի գդալ: Թրմել 3-5 րոպե:',
          storage_method:'Պահել չոր տեղում 20 ° С-ից ոչ բարձր ջերմաստիճանում: Պահպանման ժամկետը` 2 տարի:'
        },
        ru:{
          img: 'assets/images/products/teas/07.png',
          name: 'МЯТА',
          short:'Выбранный для вас природный чай, изготовленный из верхних листьев мяты...',
          description:'Выбранный для вас природный чай, изготовленный из верхних листьев мяты, обработан без химического вмешательства, обладает приятным запахом, привлекает своим вкусом и внешним видом.',
          preparation:'На чашку емкостью 200 мл. необходимо ½ чайной ложки. Настаивать 3-5 минут.',
          storage_method:'Хранить в сухом месте при температуре не выше 20 ° С. Срок хранения - 2 года.'
        },
        en:{
          img: 'assets/images/products/teas/07.png',
          name: 'MINT(NANA)',
          short:'This natural herbal tea ismade from the top leaves of mint...',
          description:'This natural herbal tea ismade from the top leaves of mint, processed without chemical intervention, smells nice and tastes fantastic.',
          preparation:'For a 200 ml. cup we need ½ teaspoon of herb. Infuse for 3-5 minutes.',
          storage_method:'Keep in a dry place at temperature not above 20 ° C. Storage is 2 years.',
        }
      },
  ]


  driedFruits:Array<any>=[
      {
        am:{
          img: 'assets/images/products/dried-fruits/01.png',
          name: 'Դեղձի չիր',
          description:'Դեղձի չիր,սննդային հավելում՝ հակաօքսիդանտ,պահածոյացնող նյութ՝ ծծումբի երկօքսիդ։Սննդային արժեքը 100գ մթերքում (միջին արժեքներ)՝ ածխաջրեր՝ 61․0գ,ճարպեր՝ 0գ,սպիտակուցներ՝ 4․0գ,՝ներգետիկ արժեքը(կալորիականությունը)՝ 1100կՋ(260 կկալ)։Անվանական զանգվածը՝ 200գ։',
          storage_method:'Պահել +5°C-ից +20°C ջերմաստիճանի և օդի 75% հարաբերական խոնավության պայմաններում։Պիտանելիության ժամկետը՝ 12 ամիս։'
        },
        ru:{
          img: 'assets/images/products/dried-fruits/01.png',
          name: 'Сухофрукты из персика',
          description:'Сушеные персики, пищевая добавка: антиоксидант, консервант: диоксид серы.Пищевая ценность на 100 г пищи (средние значения): углеводы: 61․0 г, жиры: 0 г, белки: 4․0 г, энергетическая ценность (калорий: 260 ккал): 1100 кДж. Номинальная масса нетто: 200 г.',
          storage_method:'Хранить при температуре от + 5 ° С до + 20 ° С и относительной влажности воздуха 75%. Срок годности: 12 месяцев.'
        },
        en:{
          img: 'assets/images/products/dried-fruits/01.png',
          name: 'Dried peaches',
          description:'Dried peaches, food additive: antioxidant, preservative: sulfur dioxide. Nutritional value per 100 g of food (average values): carbohydrates: 61․0 g, fat: 0 g, proteins: 4․0 g, energy value (calories: 260 kcal ): 1100 kJ. Nominal net weight: 200 g.',
          storage_method:'Store at temperatures from + 5 ° С to + 20 ° С and relative humidity no more than 75%. Shelf life: 12 months.',
        }
      },
      {
        am:{
          img: 'assets/images/products/dried-fruits/02.png',
          name: 'Սալորի չիր',
          description:'Սալորի չիր։Սննդային արժեքը 100գ մթերքում (միջին արժեքներ)՝ ածխաջրեր՝ 64․0գ,ճարպեր՝ 0գ,սպիտակուցներ՝ 2․0գ,՝ներգետիկ արժեքը(կալորիականությունը)՝ 1120 կՋ(260 կկալ)։Անվանական զանգվածը՝ 200գ։',
          storage_method:'Պահել +5°C-ից +20°C ջերմաստիճանի և օդի 75% հարաբերական խոնավության պայմաններում։Պիտանելիության ժամկետը՝ 12 ամիս։'
        },
        ru:{
          img: 'assets/images/products/dried-fruits/02.png',
          name: 'Сухофрукты из чернослива',
          description:'Сушеные чёрные сливы.Пищевая ценность на 100 г пищи (средние значения): углеводы: 64․0 г, жиры: 0 г, белки: 2․0 г, энергетическая ценность (калорий: 260 ккал): 1120 кДж. Номинальная масса нетто: 200 г.',
          storage_method:'Хранить при температуре от + 5 ° С до + 20 ° С и относительной влажности воздуха 75%. Срок годности: 12 месяцев.'
        },
        en:{
          img: 'assets/images/products/dried-fruits/02.png',
          name: 'Dried black plums',
          description:'Dried black plums. Nutritional value per 100 g of food (average values): carbohydrates: 64․0 g, fat: 0 g, proteins: 2․0 g, energy value (calories: 260 kcal ): 1120 kJ. Nominal net weight: 200 g.',
          storage_method:'Store at temperatures from + 5 ° С to + 20 ° С and relative humidity no more than 75%. Shelf life: 12 months.',
        }
      },
      {
        am:{
          img: 'assets/images/products/dried-fruits/03.png',
          name: 'Տանձի չիր',
          description:'Տանձի չիր,սննդային հավելում՝ հակաօքսիդանտ,պահածոյացնող նյութ՝ ծծումբի երկօքսիդ։Սննդային արժեքը 100գ մթերքում (միջին արժեքներ)՝ ածխաջրեր՝ 70․0գ,ճարպեր՝ 0գ,սպիտակուցներ՝ 2․0գ,՝ներգետիկ արժեքը(կալորիականությունը)՝ 1220 կՋ(290 կկալ)։Անվանական զանգվածը՝ 200գ։',
          storage_method:'Պահել +5°C-ից +20°C ջերմաստիճանի և օդի 75% հարաբերական խոնավության պայմաններում։Պիտանելիության ժամկետը՝ 12 ամիս։'
        },
        ru:{
          img: 'assets/images/products/dried-fruits/03.png',
          name: 'Сухофрукты из груши',
          description:'Сушеные персики, пищевая добавка: антиоксидант, консервант: диоксид серы.Пищевая ценность на 100 г пищи (средние значения): углеводы: 70․0 г, жиры: 0 г, белки: 2․0 г, энергетическая ценность (калорий: 290 ккал): 1220 кДж. Номинальная масса нетто: 200 г.',
          storage_method:'Хранить при температуре от + 5 ° С до + 20 ° С и относительной влажности воздуха 75%. Срок годности: 12 месяцев.'
        },
        en:{
          img: 'assets/images/products/dried-fruits/03.png',
          name: 'Dried pears',
          description:'Dried pears, food additive: antioxidant, preservative: sulfur dioxide. Nutritional value per 100 g of food (average values): carbohydrates: 70․0 g, fat: 0 g, proteins: 2․0 g, energy value (calories: 290 kcal ): 1220 kJ. Nominal net weight: 200 g.',
          storage_method:'Store at temperatures from + 5 ° С to + 20 ° С and relative humidity no more than 75%. Shelf life: 12 months.',
        }
      },
      {
        am:{
          img: 'assets/images/products/dried-fruits/04.png',
          name: 'Սպիտակ թզի չիր',
          description:'Սպիտակ թզի չիր,սննդային հավելում՝ հակաօքսիդանտ,պահածոյացնող նյութ՝ ծծումբի երկօքսիդ։Սննդային արժեքը 100գ մթերքում (միջին արժեքներ)՝ ածխաջրեր՝ 64․0գ,ճարպեր՝ 1.0գ,սպիտակուցներ՝ 3․0 գ,՝ներգետիկ արժեքը(կալորիականությունը)՝ 1180կՋ(280 կկալ)։Անվանական զանգվածը՝ 200գ։',
          storage_method:'Պահել +5°C-ից +20°C ջերմաստիճանի և օդի 75% հարաբերական խոնավության պայմաններում։Պիտանելիության ժամկետը՝ 12 ամիս։'
        },
        ru:{
          img: 'assets/images/products/dried-fruits/04.png',
          name: 'Сухофрукты из белого инжира',
          description:'Сушеные белые инжир, пищевая добавка: антиоксидант, консервант: диоксид серы.Пищевая ценность на 100 г пищи (средние значения): углеводы: 64․0 г, жиры: 1.0 г, белки: 3․0 г, энергетическая ценность (калорий: 280 ккал): 1180 кДж. Номинальная масса нетто: 200 г.',
          storage_method:'Хранить при температуре от + 5 ° С до + 20 ° С и относительной влажности воздуха 75%. Срок годности: 12 месяцев.'
        },
        en:{
          img: 'assets/images/products/dried-fruits/04.png',
          name: 'Dried figs',
          description:'Dried figs, food additive: antioxidant, preservative: sulfur dioxide. Nutritional value per 100 g of food (average values): carbohydrates: 64․0 g, fat: 1.0 g, proteins: 3․0 g, energy value (calories: 280 kcal ): 1180 kJ. Nominal net weight: 200 g.',
          storage_method:'Store at temperatures from + 5 ° С to + 20 ° С and relative humidity no more than 75%. Shelf life: 12 months.',
        }
      },{
        am:{
          img: 'assets/images/products/dried-fruits/05.png',
          name: 'Ծիրանի չիր',
          description:'Ծիրանի չիր,սննդային հավելում՝ հակաօքսիդանտ,պահածոյացնող նյութ՝ ծծումբի երկօքսիդ։Սննդային արժեքը 100գ մթերքում (միջին արժեքներ)՝ ածխաջրեր՝ 63․0 գ,ճարպեր՝ 0գ,սպիտակուցներ՝ 3․0 գ,՝ներգետիկ արժեքը(կալորիականությունը)՝ 1120կՋ(260 կկալ)։Անվանական զանգվածը՝ 200գ։',
          storage_method:'Պահել +5°C-ից +20°C ջերմաստիճանի և օդի 75% հարաբերական խոնավության պայմաններում։Պիտանելիության ժամկետը՝ 12 ամիս։'
        },
        ru:{
          img: 'assets/images/products/dried-fruits/05.png',
          name: 'Сухофрукты из абрикоса(курага)',
          description:'Сушеные абрикосы, пищевая добавка: антиоксидант, консервант: диоксид серы.Пищевая ценность на 100 г пищи (средние значения): углеводы: 63․0 г, жиры: 0 г, белки: 3․0 г, энергетическая ценность (калорий: 260 ккал): 1120 кДж. Номинальная масса нетто: 200 г.',
          storage_method:'Хранить при температуре от + 5 ° С до + 20 ° С и относительной влажности воздуха 75%. Срок годности: 12 месяцев.'
        },
        en:{
          img: 'assets/images/products/dried-fruits/05.png',
          name: 'Dried apricots',
          description:'Dried apricots, food additive: antioxidant, preservative: sulfur dioxide. Nutritional value per 100 g of food (average values): carbohydrates: 63․0 g, fat: 0 g, proteins: 3․0 g, energy value (calories: 260 kcal ): 1120 kJ. Nominal net weight: 200 g.',
          storage_method:'Store at temperatures from + 5 ° С to + 20 ° С and relative humidity no more than 75%. Shelf life: 12 months.',
        }
      },{
        am:{
          img: 'assets/images/products/dried-fruits/06.png',
          name: 'Խնձորի չիր',
          description:'Խնձորի չիր,սննդային հավելում՝ հակաօքսիդանտ,պահածոյացնող նյութ՝ ծծումբի երկօքսիդ։Սննդային արժեքը 100գ մթերքում (միջին արժեքներ)՝ ածխաջրեր՝ 66․0գ,ճարպեր՝ 0գ,սպիտակուցներ՝ 1․0գ,՝ներգետիկ արժեքը(կալորիականությունը)՝ 1140 կՋ(270 կկալ)։Անվանական զանգվածը՝ 200գ։',
          storage_method:'Պահել +5°C-ից +20°C ջերմաստիճանի և օդի 75% հարաբերական խոնավության պայմաններում։Պիտանելիության ժամկետը՝ 12 ամիս։'
        },
        ru:{
          img: 'assets/images/products/dried-fruits/06.png',
          name: 'Сухофрукты из яблок',
          description:'Сушеные яблоки, пищевая добавка: антиоксидант, консервант: диоксид серы.Пищевая ценность на 100 г пищи (средние значения): углеводы: 66․0 г, жиры: 0 г, белки: 1․0 г, энергетическая ценность (калорий: 270 ккал): 1140 кДж. Номинальная масса нетто: 200 г.',
          storage_method:'Хранить при температуре от + 5 ° С до + 20 ° С и относительной влажности воздуха 75%. Срок годности: 12 месяцев.'
        },
        en:{
          img: 'assets/images/products/dried-fruits/06.png',
          name: 'Dried apples',
          description:'Dried apples, food additive: antioxidant, preservative: sulfur dioxide. Nutritional value per 100 g of food (average values): carbohydrates: 66․0 g, fat: 0 g, proteins: 1․0 g, energy value (calories: 270 kcal ): 1140 kJ. Nominal net weight: 200 g.',
          storage_method:'Store at temperatures from + 5 ° С to + 20 ° С and relative humidity no more than 75%. Shelf life: 12 months.',
        }
      }
  ]

  souvenirs:Array<any>=[
    {
      am:{
        img: 'assets/images/products/souvenirs/1.jpg',
        name: 'Մեծ գիրք N1',
        description:'<p><label>Թեյի տեսակ: </label>Ասորտի</p><p><label>Նվերի տեսակ։ </label>Ապրանքներ </p><p><label>Փաթեթավորում։ </label>Փայտե տուփ </p><p><label>Փաթեթավորման լայնությունը։ </label>40սմ </p><p><label>Փաթեթավորման բարձրությունը։ </label>30սմ </p><p><label>Քաշը։ </label>1400գ</p><p><label>Պահպանման առավելագույն ջերմաստիճանը: </label> +20 °C </p><p><label>Պահպանման ժամկետը։ </label>24 ամիս</p><p><label>Թեյի թողարկման ձև։ </label>Տերևային</p><p><label>Թողարկման ձև։ </label>Նվերի տուփ բաղկացած 8տուփ թեյից։ </p>',
      },
      ru:{
        img: 'assets/images/products/souvenirs/1.jpg',
        name: 'Большая книга N1',
        description:'<p><label>Вид чая: </label>Ассорти</p><p><label>Тип подарка: </label>Продукты</p><p><label>Упаковка: </label>Деревянная коробка</p><p><label>Ширина упаковки: </label>40см</p><p><label>Высота упаковки: </label>30см</p><p><label>Вес։ </label>1400г</p><p><label>Максимальная температура хранения: </label>+20 °C</p><p><label>Срок годности: </label>24 месяца</p><p><label>Форма выпуска напитка: </label>рассыпной</p><p><label>Комплектация: </label>подарочная коробка, чай - 8 уп.</p>',
      },
      en:{
        img: 'assets/images/products/souvenirs/1.jpg',
        name: 'Big book N1',
        description:'<p><label>Type of tea: </label>Assorti</p><p><label>Gift type: </label>Products</p><p><label>Package: </label>Wooden box</p><p><label>Packing width: </label>40cm</p><p><label>Packing height: </label>30cm</p><p><label>Weight:</label> 1400g</p><p><label>Maximum storage temperature: </label>+20 °C</p><p><label>Shelf life: </label>2 years</p><p><label>Release form: </label> Loose</p><p><label>Equipment: </label>Gift box, tea - 8 pack.</p>',
      }
    },
    {
      am:{
        img: 'assets/images/products/souvenirs/2.jpg',
        name: 'Մեծ գիրք N2',
        description:'<p><label>Թեյի տեսակ: </label>Ասորտի</p><p><label>Նվերի տեսակ։ </label>Ապրանքներ </p><p><label>Փաթեթավորում։ </label>Փայտե տուփ </p><p><label>Փաթեթավորման լայնությունը։ </label>40սմ </p><p><label>Փաթեթավորման բարձրությունը։ </label>30սմ </p><p><label>Քաշը։ </label>1400գ</p><p><label>Պահպանման առավելագույն ջերմաստիճանը: </label> +20 °C </p><p><label>Պահպանման ժամկետը։ </label>24 ամիս</p><p><label>Թեյի թողարկման ձև։ </label>Տերևային</p><p><label>Թողարկման ձև։ </label>Նվերի տուփ բաղկացած 8տուփ թեյից։ </p>',
      },
      ru:{
        img: 'assets/images/products/souvenirs/2.jpg',
        name: 'Большая книга N2',
        description:'<p><label>Вид чая: </label>Ассорти</p><p><label>Тип подарка: </label>Продукты</p><p><label>Упаковка: </label>Деревянная коробка</p><p><label>Ширина упаковки: </label>40см</p><p><label>Высота упаковки: </label>30см</p><p><label>Вес։ </label>1400г</p><p><label>Максимальная температура хранения: </label>+20 °C</p><p><label>Срок годности: </label>24 месяца</p><p><label>Форма выпуска напитка: </label>рассыпной</p><p><label>Комплектация: </label>подарочная коробка, чай - 8 уп.</p>',
      },
      en:{
        img: 'assets/images/products/souvenirs/2.jpg',
        name: 'Big book N2',
        description:'<p><label>Type of tea: </label>Assorti</p><p><label>Gift type: </label>Products</p><p><label>Package: </label>Wooden box</p><p><label>Packing width: </label>40cm</p><p><label>Packing height: </label>30cm</p><p><label>Weight:</label> 1400g</p><p><label>Maximum storage temperature: </label>+20 °C</p><p><label>Shelf life: </label>2 years</p><p><label>Release form: </label> Loose</p><p><label>Equipment: </label>Gift box, tea - 8 pack.</p>',
      }
    },
    {
      am:{
        img: 'assets/images/products/souvenirs/3.jpg',
        name: 'Մեծ գիրք N3',
        description:'<p><label>Թեյի տեսակ: </label>Ասորտի</p><p><label>Նվերի տեսակ։ </label>Ապրանքներ </p><p><label>Փաթեթավորում։ </label>Փայտե տուփ </p><p><label>Փաթեթավորման լայնությունը։ </label>40սմ </p><p><label>Փաթեթավորման բարձրությունը։ </label>30սմ </p><p><label>Քաշը։ </label>1400գ</p><p><label>Պահպանման առավելագույն ջերմաստիճանը: </label> +20 °C </p><p><label>Պահպանման ժամկետը։ </label>24 ամիս</p><p><label>Թեյի թողարկման ձև։ </label>Տերևային</p><p><label>Թողարկման ձև։ </label>Նվերի տուփ բաղկացած 8տուփ թեյից։ </p>',
      },
      ru:{
        img: 'assets/images/products/souvenirs/3.jpg',
        name: 'Большая книга N3',
        description:'<p><label>Вид чая: </label>Ассорти</p><p><label>Тип подарка: </label>Продукты</p><p><label>Упаковка: </label>Деревянная коробка</p><p><label>Ширина упаковки: </label>40см</p><p><label>Высота упаковки: </label>30см</p><p><label>Вес։ </label>1400г</p><p><label>Максимальная температура хранения: </label>+20 °C</p><p><label>Срок годности: </label>24 месяца</p><p><label>Форма выпуска напитка: </label>рассыпной</p><p><label>Комплектация: </label>подарочная коробка, чай - 8 уп.</p>',
      },
      en:{
        img: 'assets/images/products/souvenirs/3.jpg',
        name: 'Big book N3',
        description:'<p><label>Type of tea: </label>Assorti</p><p><label>Gift type: </label>Products</p><p><label>Package: </label>Wooden box</p><p><label>Packing width: </label>40cm</p><p><label>Packing height: </label>30cm</p><p><label>Weight:</label> 1400g</p><p><label>Maximum storage temperature: </label>+20 °C</p><p><label>Shelf life: </label>2 years</p><p><label>Release form: </label> Loose</p><p><label>Equipment: </label>Gift box, tea - 8 pack.</p>',
      }
    },
    {
      am:{
        img: 'assets/images/products/souvenirs/4.jpg',
        name: 'Փոքր գիրք N1',
        description:'<p><label>Թեյի տեսակ: </label>Ասորտի</p><p><label>Նվերի տեսակ։ </label>Ապրանքներ </p><p><label>Փաթեթավորում։ </label>Փայտե տուփ </p><p><label>Փաթեթավորման լայնությունը։ </label>22սմ </p><p><label>Փաթեթավորման բարձրությունը։ </label>30սմ </p><p><label>Քաշը։ </label>850գ</p><p><label>Պահպանման առավելագույն ջերմաստիճանը: </label> +20 °C </p><p><label>Պահպանման ժամկետը։ </label>24 ամիս</p><p><label>Թեյի թողարկման ձև։ </label>Տերևային</p><p><label>Թողարկման ձև։ </label>Նվերի տուփ բաղկացած 8տուփ թեյից։ </p>',
      },
      ru:{
        img: 'assets/images/products/souvenirs/4.jpg',
        name: 'Маленькая книга N1',
        description:'<p><label>Вид чая: </label>Ассорти</p><p><label>Тип подарка: </label>Продукты</p><p><label>Упаковка: </label>Деревянная коробка</p><p><label>Ширина упаковки: </label>22см</p><p><label>Высота упаковки: </label>30 см</p><p><label>Вес։ </label>85г</p><p><label>Максимальная температура хранения: </label>+20 °C</p><p><label>Срок годности: </label>24 месяца</p><p><label>Форма выпуска напитка: </label>рассыпной</p><p><label>Комплектация: </label>подарочная коробка, чай - 8 уп.</p>',
      },
      en:{
        img: 'assets/images/products/souvenirs/4.jpg',
        name: 'Small book N1',
        description:'<p><label>Type of tea: </label>Assorti</p><p><label>Gift type: </label>Products</p><p><label>Package: </label>Wooden box</p><p><label>Packing width: </label>22cm</p><p><label>Packing height: </label>30cm</p><p><label>Weight:</label>850g</p><p><label>Maximum storage temperature: </label>+20 °C</p><p><label>Shelf life: </label>2 years</p><p><label>Release form: </label> Loose</p><p><label>Equipment: </label>Gift box, tea - 8 pack.</p>',
      }
    },
    {
      am:{
        img: 'assets/images/products/souvenirs/5.jpg',
        name: 'Փոքր գիրք N2',
        description:'<p><label>Թեյի տեսակ: </label>Ասորտի</p><p><label>Նվերի տեսակ։ </label>Ապրանքներ </p><p><label>Փաթեթավորում։ </label>Փայտե տուփ </p><p><label>Փաթեթավորման լայնությունը։ </label>22սմ </p><p><label>Փաթեթավորման բարձրությունը։ </label>30սմ </p><p><label>Քաշը։ </label>850գ</p><p><label>Պահպանման առավելագույն ջերմաստիճանը: </label> +20 °C </p><p><label>Պահպանման ժամկետը։ </label>24 ամիս</p><p><label>Թեյի թողարկման ձև։ </label>Տերևային</p><p><label>Թողարկման ձև։ </label>Նվերի տուփ բաղկացած 8տուփ թեյից։ </p>',
      },
      ru:{
        img: 'assets/images/products/souvenirs/5.jpg',
        name: 'Маленькая книга N2',
        description:'<p><label>Вид чая: </label>Ассорти</p><p><label>Тип подарка: </label>Продукты</p><p><label>Упаковка: </label>Деревянная коробка</p><p><label>Ширина упаковки: </label>22см</p><p><label>Высота упаковки: </label>30 см</p><p><label>Вес։ </label>85г</p><p><label>Максимальная температура хранения: </label>+20 °C</p><p><label>Срок годности: </label>24 месяца</p><p><label>Форма выпуска напитка: </label>рассыпной</p><p><label>Комплектация: </label>подарочная коробка, чай - 8 уп.</p>',
      },
      en:{
        img: 'assets/images/products/souvenirs/5.jpg',
        name: 'Small book N2',
        description:'<p><label>Type of tea: </label>Assorti</p><p><label>Gift type: </label>Products</p><p><label>Package: </label>Wooden box</p><p><label>Packing width: </label>22cm</p><p><label>Packing height: </label>30cm</p><p><label>Weight:</label>850g</p><p><label>Maximum storage temperature: </label>+20 °C</p><p><label>Shelf life: </label>2 years</p><p><label>Release form: </label> Loose</p><p><label>Equipment: </label>Gift box, tea - 8 pack.</p>',
      }
    },
    {
      am:{
        img: 'assets/images/products/souvenirs/6.jpg',
        name: 'Փոքր գիրք N3',
        description:'<p><label>Թեյի տեսակ: </label>Ասորտի</p><p><label>Նվերի տեսակ։ </label>Ապրանքներ </p><p><label>Փաթեթավորում։ </label>Փայտե տուփ </p><p><label>Փաթեթավորման լայնությունը։ </label>22սմ </p><p><label>Փաթեթավորման բարձրությունը։ </label>30սմ </p><p><label>Քաշը։ </label>850գ</p><p><label>Պահպանման առավելագույն ջերմաստիճանը: </label> +20 °C </p><p><label>Պահպանման ժամկետը։ </label>24 ամիս</p><p><label>Թեյի թողարկման ձև։ </label>Տերևային</p><p><label>Թողարկման ձև։ </label>Նվերի տուփ բաղկացած 8տուփ թեյից։ </p>',
      },
      ru:{
        img: 'assets/images/products/souvenirs/6.jpg',
        name: 'Маленькая книга N3',
        description:'<p><label>Вид чая: </label>Ассорти</p><p><label>Тип подарка: </label>Продукты</p><p><label>Упаковка: </label>Деревянная коробка</p><p><label>Ширина упаковки: </label>22см</p><p><label>Высота упаковки: </label>30 см</p><p><label>Вес։ </label>85г</p><p><label>Максимальная температура хранения: </label>+20 °C</p><p><label>Срок годности: </label>24 месяца</p><p><label>Форма выпуска напитка: </label>рассыпной</p><p><label>Комплектация: </label>подарочная коробка, чай - 8 уп.</p>',
      },
      en:{
        img: 'assets/images/products/souvenirs/6.jpg',
        name: 'Small book N3',
        description:'<p><label>Type of tea: </label>Assorti</p><p><label>Gift type: </label>Products</p><p><label>Package: </label>Wooden box</p><p><label>Packing width: </label>22cm</p><p><label>Packing height: </label>30cm</p><p><label>Weight:</label>850g</p><p><label>Maximum storage temperature: </label>+20 °C</p><p><label>Shelf life: </label>2 years</p><p><label>Release form: </label> Loose</p><p><label>Equipment: </label>Gift box, tea - 8 pack.</p>',
      }
    }
  ]

  ngOnInit(): void {}

  openProductDialog(product) {
    this.dialog.open(ProductPopupComponent, { data: product });
  }
}

@Component({
  selector: 'app-product-popup',
  templateUrl: './product-popup.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductPopupComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ProductPopupComponent,
    public dialogRef: MatDialogRef<ProductPopupComponent>
  ) {
    this.product = this.data;
  }

  product: any = [];
  ngOnInit(): void {}

  closeModal() {
    this.dialogRef.close();
  }
}
