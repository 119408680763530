<div class="about-page page">
    <section class="top-banner">
        <div class="mask"></div>
        <div class="container">
            <h2 class="title">{{'INTROS.ABOUT' | translate}}</h2>
            <div class="links">
                <a routerLink="/home">{{'MENU.HOME' | translate}}</a
        ><span><i class="fas fa-chevron-right"></i></span>
        <a>{{'INTROS.ABOUT' | translate}}</a>
            </div>
        </div>
    </section>
    <section class="about-company light-bg">
        <div class="container">
            <h3 class="section-intro">{{'INTROS.ABOUT' | translate}}</h3>
            <h2 class="section-title">{{'ABOUT.TITLE' | translate}}</h2>
            <p class="description text-center">
                {{'ABOUT.DESCRIPTION' | translate}}
            </p>
            <div class="info-block">
                <div class="company-transmissions row">
                    <div class="col-xl-2 col-lg-3 col-md-4 col-sm-12 item mb-3">
                        <div class="icon">
                            <img src="assets/images/icons/icon-1.png" />
                        </div>
                        <h3 class="title">{{'ABOUT.STEPS.1.TITLE' | translate}}</h3>
                        <p class="description">
                            {{'ABOUT.STEPS.1.DESCRIPTION' | translate}}
                        </p>
                    </div>
                    <div class="col-xl-2 col-lg-3 col-md-4 col-sm-12 item mb-3">
                        <div class="icon">
                            <img src="assets/images/icons/icon-2.png" />
                        </div>
                        <h3 class="title">{{'ABOUT.STEPS.2.TITLE' | translate}}</h3>
                        <p class="description">
                            {{'ABOUT.STEPS.2.DESCRIPTION' | translate}}
                        </p>
                    </div>
                    <div class="col-xl-2 col-lg-3 col-md-4 col-sm-12 item mb-3">
                        <div class="icon">
                            <img src="assets/images/icons/icon-3.png" />
                        </div>
                        <h3 class="title">{{'ABOUT.STEPS.3.TITLE' | translate}}</h3>
                        <p class="description">
                            {{'ABOUT.STEPS.3.DESCRIPTION' | translate}}
                        </p>
                    </div>
                    <div class="col-xl-2 col-lg-3 col-md-4 col-sm-12 item mb-3">
                        <div class="icon">
                            <img src="assets/images/icons/icon-4.png" />
                        </div>
                        <h3 class="title">{{'ABOUT.STEPS.4.TITLE' | translate}}</h3>
                        <p class="description">
                            {{'ABOUT.STEPS.4.DESCRIPTION' | translate}}
                        </p>
                    </div>
                    <div class="col-xl-2 col-lg-3 col-md-4 col-sm-12 item mb-3">
                        <div class="icon">
                            <img src="assets/images/icons/icon-5.png" />
                        </div>
                        <h3 class="title">{{'ABOUT.STEPS.5.TITLE' | translate}}</h3>
                        <p class="description">
                            {{'ABOUT.STEPS.5.DESCRIPTION' | translate}}
                        </p>
                    </div>
                    <div class="col-xl-2 col-lg-3 col-md-4 col-sm-12 item mb-3">
                        <div class="icon">
                            <img src="assets/images/icons/icon-6.png" />
                        </div>
                        <h3 class="title">{{'ABOUT.STEPS.6.TITLE' | translate}}</h3>
                        <p class="description">
                            {{'ABOUT.STEPS.6.DESCRIPTION' | translate}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="certificates light-bg">
        <div class="container">
            <h3 class="section-intro">{{'INTROS.CERTIFICATES' | translate}}</h3>
            <h2 class="section-title">{{'ABOUT.TITLE_CERTIFICATES' | translate}}</h2>
            <div class="row certificate-block">
                <div class="col-lg-4 col-sm-12 mb-3">
                    <img src="assets/images/certificate1.jpg" />
                </div>
                <div class="col-lg-4 col-sm-12 mb-3">
                    <img src="assets/images/certificate3.png" />
                </div>
                <div class="col-lg-4 col-sm-12 mb-3">
                    <img src="assets/images/certificate2.jpg" />
                </div>
                <div class="col-lg-4 col-sm-12 mb-3">
                    <img src="assets/images/certificate4.jpg" />
                </div>
                <div class="col-lg-4 col-sm-12 mb-3">
                    <img src="assets/images/certificate5.jpg" />
                </div>
                <div class="col-lg-4 col-sm-12 mb-3">
                    <img src="assets/images/certificate6.jpg" />
                </div>
                <div class="col-lg-4 col-sm-12 mb-3">
                    <img src="assets/images/certificate7.jpg" />
                </div>
                <div class="col-lg-4 col-sm-12 mb-3">
                    <img src="assets/images/certificate8.jpg" />
                </div>
                <div class="col-lg-4 col-sm-12 mb-3">
                    <img src="assets/images/certificate9.jpg" />
                </div>
                <div class="col-lg-4 col-sm-12 mb-3">
                    <img src="assets/images/certificate10.jpg" />
                </div>
                <div class="col-lg-4 col-sm-12 mb-3">
                    <img src="assets/images/certificate11.jpg" />
                </div>
                <div class="col-lg-4 col-sm-12 mb-3">
                    <img src="assets/images/certificate12.jpg" />
                </div>
            </div>
        </div>
    </section>
    <section class="contacts light-bg">
        <div class="container">
            <h2 class="section-title">{{'CONTACTS.TITLE' | translate}}</h2>
            <p class="description">
                {{'CONTACTS.DESCRIPTION' | translate}}
            </p>
            <div class="contacts-container">
                <div class="item">
                    <div class="icon">
                        <i class="fas fa-phone-alt"></i>
                    </div>
                    <div class="text">+374 93 71-21-17</div>
                </div>
                <div class="item">
                    <div class="icon">
                        <i class="fas fa-envelope"></i>
                    </div>
                    <div class="text">armeniankingdom@mail.ru</div>
                </div>
                <div class="item">
                    <div class="icon">
                        <i class="fas fa-map-marker-alt"></i>
                    </div>
                    <div class="text">{{'CONTACTS.ADDRESS' | translate}}</div>
                </div>
            </div>
            <div class="form-container mt-4">
                <div class="row">
                    <div class="col-lg-5 col-sm-12">
                        <form>
                            <mat-form-field class="full-width" appearance="fill">
                                <mat-label>{{'CONTACTS.FORM.FULL_NAME' | translate}}</mat-label>
                                <input matInput />
                            </mat-form-field>
                            <mat-form-field class="full-width" appearance="fill">
                                <mat-label>{{'CONTACTS.FORM.EMAIL' | translate}}</mat-label>
                                <input matInput />
                            </mat-form-field>
                            <mat-form-field class="full-width" appearance="fill">
                                <mat-label>{{'CONTACTS.FORM.MESSAGE' | translate}}</mat-label>
                                <textarea rows="5" matInput></textarea>
                            </mat-form-field>
                            <div class="d-flex justify-content-end">
                                <button class="kng-btn">
                  <i class="fas fa-paper-plane"></i><span >{{'BUTTONS.SEND' | translate}}</span>
                </button>
                            </div>
                        </form>
                    </div>
                    <div class="col-lg-7 col-sm-12">
                        <div style="position: relative; overflow: hidden">
                            <a href="https://yandex.ru/maps/10262/yerevan/?utm_medium=mapframe&utm_source=maps" style="
                  color: #eee;
                  font-size: 12px;
                  position: absolute;
                  top: 0px;
                ">Ереван</a
              ><a
                href="https://yandex.ru/maps/10262/yerevan/geo/1513618756/?ll=44.489250%2C40.214779&utm_medium=mapframe&utm_source=maps&z=17.34"
                style="
                  color: #eee;
                  font-size: 12px;
                  position: absolute;
                  top: 14px;
                "
                >Улица Сасна Црери — Яндекс.Карты</a
              ><iframe
                src="https://yandex.ru/map-widget/v1/-/CCUiUQSklC"
                width="100%"
                height="400"
                frameborder="1"
                allowfullscreen="true"
                style="position: relative"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>