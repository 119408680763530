import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-we-represented',
  templateUrl: './we-represented.component.html',
  styleUrls: ['./we-represented.component.scss'],
})
export class WeRepresentedComponent implements OnInit {
  currentLng:string = 'am'
  constructor(private service:LanguageService) {
    this.service.getLanguage.subscribe(res=>{
      this.currentLng = res;
    })
  }

  ngOnInit(): void {}

  data:any = {
    am: [
      {title:"Հայաստան",icon:"assets/images/flags/armenia.svg",list:[
        {name:"Երևան Սիթի Սուպերմարկետներ",address:"",phone:""},
        {name:"Նոր Զովք",address:"",phone:""},
        {name:"Բոնուս Սուպերմարկետ",address:"",phone:""},
        {name:"Ցիտրուս սուպերմարկետ",address:"Ֆրունզե 10/1",phone:""},
        {name:"Նռնենի Հաուս",address:"ք. Երևան, Պուշկին 21",phone:"+374 44 999930 / +374 11 999930"},
        {name:"Պնդուկ",address:"ք. Երևան Մաշտոցի պողոտա 24 / Մաշտոցի պողոտա 40",phone:""},
        {name:"Օպերա Մարկետ",address:"",phone:""},
        {name:"Է.Ա.Կ. բուտիկ",address:"Բաղրամյան 77/5",phone:""},
        {name:"Լևոն  և Լամարա դեղատների ցանց",address:"",phone:""},
        {name:"EVA beauty store",address:"ք. Երևան Կիևյան փողոց",phone:"+374 94 783381"},
        {name:"Սուրմալու Առևտրի Կենտրոն",address:"",phone:""},
        {name:"Փեթակ առևտրի կենտրոն",address:"",phone:""},
        {name:"Վերնիսաժ",address:"ք. Երևան Խանջյան փողոց",phone:""},
        {name:"Վան մարկետ",address:"ք. Էջմիածին,Խորենացի 48",phone:""},
        {name:"Օրիգինալ Սուպերմարկետ",address:"ք. Աբովյան,Հանրապետության 22/10",phone:""},
        {name:"Սաֆարի Սուպերմարկետ",address:"ք․ Չարենցավան",phone:""},
        {name:"LA Coffee",address:"ք․ Սևան,Սայաթ Նովա 22",phone:""},
        {name:"Վահագնի մարկետ",address:"Աշտարակի խճուղի 7,Վահագնի թաղամաս",phone:""},
        {name:"Զոլաքար գյուղ",address:"ք․ Մարտունի,գ․ Զոլաքար,1-ին փողոց 4 շ.",phone:""},
        {name:"Coffee Tun",address:"ք․ Վանաձոր,Վարդանանց Փողոց",phone:"+374 94 000606"},
        {name:"Duty Free",address:"Զվարթնոց› օդանավակայանի մեկնման սրահ,հայկական ապրանքանիշերի բաժին",phone:""},
      ]},
      {title:"ԱՄՆ",icon:"assets/images/flags/usa.svg",list:[
        {name:"Ջոնս մարկետ",address:"",phone:""},
        {name:"Օլդ ֆեշն դելի",address:"",phone:""},
        {name:"Բիգ Սքուեր մարկետ",address:"",phone:""},
        {name:"Բլեքջեք մարկետ",address:"",phone:""},
        {name:"Գուդ ֆելու",address:"",phone:""},
        {name:"Յունիք մարկետ էնդ լիքյոր/Յունիք լիքյոր էնդ մարկետ",address:"",phone:""},
        {name:"Տարզանա Արմենիան դելի",address:"",phone:""},
        {name:"Վիկտորիա պրոդյուս",address:"",phone:""},
        {name:"Մուսալեռ մարկետ",address:"",phone:""},
        {name:"Բուտչեր էնդ բուզ",address:"",phone:""},
        {name:"Ոսկեվազ պրոդյուս",address:"",phone:""},
        {name:"Վալլի մարկետ փլեյս(Սիմի Վալլի)",address:"",phone:""},
        {name:"սանլենդ պրոդյուս",address:"",phone:""},
        {name:"Վալլի մարկետ փլեյսս օլլ 3",address:"",phone:""},
        {name:"Արբատ գրոսերի",address:"",phone:""},
        {name:"Նաիրի միթ էնդ դելի",address:"",phone:""},
        {name:"Գրանդ ֆուդ մարկետ",address:"",phone:""},
        {name:"Բակլավա ֆեքթորի",address:"",phone:""},
        {name:"Օսսիս բեյքերի",address:"",phone:""},
        {name:"Էլիտ մարկետ բեյքերի էնդ լիքյոր",address:"",phone:""},
        {name:"Վիկտորի պրոդյուս",address:"",phone:""},
        {name:"Սընշայն բեյքերի էնդ պաստերի",address:"",phone:""},
        {name:"Սուզըս գարդեն էնդ դելայթս ֆլովերս",address:"",phone:""},
        {name:"Բուրբանկ մարկետս էնդ բեյքերի",address:"",phone:""},
        {name:"Բակլավա մարկետս էնդ բեյքերի/Ռեդ Թոփ մարկետ էնդ քիթչեն",address:"",phone:""},
        {name:"Բակլավա ֆեքթորի ին նորթ Հոլլիվուդ էնդ Գլենդել",address:"",phone:""},
        {name:"Վան բեյքերի նորթ Հոլլիվուդ էնդ Հոլլիվուդ",address:"",phone:""},
        {name:"Նելլիս Բեյքերի",address:"",phone:""},
        {name:"Տիգրանակերտ մարկետ",address:"",phone:""},
        {name:"Բիգ Սքուեյր, Լոս Անջելես",address:"",phone:""},
        {name:"Արմաշ պրոդյուս, Լոս Անջելես",address:"",phone:""},
        {name:"Տիգրանակերտ մարկետ, Լոս Անջելես",address:"",phone:""},
        {name:"Բակլավա ֆեքթորի, Լոս Անջելես",address:"",phone:""},
        {name:"Տերրամո կաֆե, Լոս Անջելես",address:"",phone:""},
        {name:"Վիքթորի պրոդյուս, Լոս Անջելես",address:"",phone:""},
        {name:"Օսսիս բեյքերի, Լոս Անջելես",address:"",phone:""},
      ]},
      {title:"Եվրոպա",icon:"assets/images/flags/european-union.svg",list:[
        {name:"Օնզե-Լիեվե-Վրուվերստատ",address:"78 Մեխելեն, Բելգիա",phone:""},
        {name:"Դավիդ Մարկետ",address:"18Ա Շարլերուա, Բելգիա",phone:"+3271160482"},
        {name:"Ռոյալ Արմենիա մինի մարկետ",address:"168 Ռուե Լեոն Տեոդոր, Ժետ, Բելգիա",phone:"+32 2 427 28 93"},
        {name:"Ֆրեշ Մեդ",address:"Ռուե դե Լեսկարդոն 35, Էտտերբերկ, Բելգիա",phone:"+32 2 736 05 28"},
        {name:"Սեվուրս դ՛Արմենի",address:"Ավենյու Լուիս Բլանկ,Ֆրանսիա",phone:""},
        {name:"Հոզաին Գուրմանդիսես",address:"Ստրասբուրգ, Ֆրանսիա",phone:"+33 7 81 87 70 46 "},
        {name:"Էպիսեր Արմ Մարկետ",address:"Ստրասբուրգ, Ֆրանսիա",phone:" 09-53-70-86-39"},
        {name:"Էպիսեր Աթենա",address:"Ստրասբուրգ, Ֆրանսիա",phone:""},
        {name:"Բոգատիր Սուպերմարկետ",address:"Հոգստրատ 95Ա,Ռոտերդամ, Հոլանդիա",phone:"+31617904128"},
        {name:"Արաքս Մարկետ",address:"Օտմարսումսեստրատ 250,Ալմելո,Հոլանդիա",phone:"0031624263197"},
        {name:"Էպիսեր Ֆայն Էրեբունիերևան",address:"54 դը՛Երևան,Մուլենու Սենթրե լես Էպինեթ",phone:""},
        {name:"Քարֆուր Բելլե-իլե",address:"Լիջ,Բելգիա",phone:"+32 4 370 95 00 "},
        {name:"Լուդաս ՍՊԸ(Առնակ և Լյուդմիլա Աբրահամյաններ)",address:"Բելգիա",phone:"+32 486 028470/+32 484 166475 "},
      ]},
      {title:"Ռուսաստան",icon:"assets/images/flags/russia.svg",list:[
        {name:"Պիտյորոչկա(հայկական տաղավար)",address:"Պարկովայա փ․8,բն․3,ք․ Ռեուտով",phone:"+79654190186"},
        {name:"Ռուսաստան,Մոսկվա",address:"Ռեուտովի փողոց Պարկովայա 8/3",phone:""},
        {name:"Նիժնի Նովգորոդ",address:"",phone:"+79092905455"},
        {name:"Մոսկվա,Միտիշչի",address:"",phone:"+79166914075"},
        {name:"Ներմուծող Անդրեյ",address:"Նիժնի Նովգորոդ",phone:"+79092905455"},
        {name:"Ներմուծող ԱՁ Միրզոյան Վարուժան",address:"Ստավրոպոլ, Յուժնի օբխոդ 51",phone:"+79097732221"},
      ]},
    ],
    ru: [
      {title:"Армения",icon:"assets/images/flags/armenia.svg",list:[
        {name:"Ереван Сити Супермаркеты",address:"",phone:""},
        {name:"Нор Зовк",address:"",phone:""},
        {name:"Бонус Супермаркет",address:"",phone:""},
        {name:"Цитрус Супермаркет",address:"Фрунзе 10/1",phone:""},
        {name:"Нрнени Хаус",address:"Ереван, Пушкина 21",phone:"+ 374 44 999930 / +374 11 999930"},
        {name:"Пндук",address:"реван проспект Маштоца 24 / проспект Маштоца 40",phone:""},
        {name:"Опера Маркет",address:"",phone:""},
        {name:"ЕАК бутик",address:"Баграмян 77/5",phone:""},
        {name:"Аптечная сеть Левон и Ламара",address:"",phone:""},
        {name:"Салон красоты EVA",address:"Ереван, ул. Киевян",phone:"+ 374 94 783381"},
        {name:"Торговый центр Сурмалу",address:"",phone:""},
        {name:"Торговый центр Петак",address:"",phone:""},
        {name:"Вернисаж",address:"Ереван улица Ханджяна",phone:""},
        {name:"Ван Маркет",address:"Эчмиадзин, ул. Хоренаци 48",phone:""},
        {name:"Оригинальный супермаркет",address:"Анрапетутян, 22/10, Абовян",phone:""},
        {name:"Супермаркет Сафари",address:"Чаренцаван",phone:""},
        {name:"Ла Коффе",address:"г. Севан,Саят-Нова 22",phone:""},
        {name:"Ваагнский рынок",address:"Аштаракское шоссе, 7, район Ваагни",phone:""},
        {name:"село Золакар",address:"Мартуни, г. Золакар, 1-я улица 4 дом",phone:""},
        {name:"Коффе Тун",address:"г. Ванадзор,ул. Вардананц",phone:"+ 374 94 000606"},
        {name: "Duty Free", address: "Зал вылета аэропорта Звартноц, Департамент армянских брендов", phone: ""},
      ]},
      {title:"Америка",icon:"assets/images/flags/usa.svg",list:[
        {name:"Джонс Маркет",address:"",phone:""},
        {name:"Олд Фешн дели",address:"",phone:""},
        {name:"Биг Скуер Маркет",address:"",phone:""},
        {name:"Блек-Джек Маркет",address:"",phone:""},
        {name:"Гуд Фелов/Гуд Фелов Маркет и Пицца",address:"",phone:""},
        {name:"Юник Маркет и Ликёр",address:"",phone:""},
        {name:"Тарзана Армениан Дели",address:"",phone:""},
        {name:"Виктори продю",address:"",phone:""},
        {name:"сМусалер маркет",address:"",phone:""},
        {name:"Бутчер енд Буз",address:"",phone:""},
        {name:"Воскеваз продуктовый",address:"",phone:""},
        {name:"Валли маркет плейс",address:"",phone:""},
        {name:"Продукция Санленд",address:"",phone:""},
        {name:"Валли маркет плейс олл 3",address:"",phone:""},
        {name:"Арбат продуктовый",address:"",phone:""},
        {name:"Мясо и деликатесы Наири ",address:"",phone:""},
        {name:"Гранд фуд маркет",address:"",phone:""},
        {name:"Баклава фабрика",address:"",phone:""},
        {name:"Пекарня Осси",address:"",phone:""},
        {name:"Элит Маркет Бейкери и Ликёр",address:"",phone:""},
        {name:"Сншайн выпечка и кондитерские изделия",address:"",phone:""},
        {name:"Сюзис гарден и дилай фловерс",address:"",phone:""},
        {name:"Бурбанк маркет и пекарня",address:"",phone:""},
        {name:"Баклава фабрика Северный Голливуд и Глендейле",address:"",phone:""},
        {name:"Ван пекарня Северный Голливуд и Голливуд",address:"",phone:""},
        {name:"Пекарня Нелли",address:"",phone:""},
        {name:"Тигранакерт маркет",address:"",phone:""},
      ]},
      {title:"Европа",icon:"assets/images/flags/european-union.svg",list:[
        {name:"Онзе-Ливе-Вруверстат",address:"Мишелен 78,Белгия",phone:""},
        {name:"Давид маркет",address:"18А Шерлерои,Белгия",phone:"+ 3271160482"},
        {name:"Мини-маркет Роял Армения",address:"168 Руе Леон Теодор,Жет,Белгия",phone:"+ 32 2 427 28 93"},
        {name:"Фреш Мед",address:"Руе де Лескардо 35,Эттербек,Белгия",phone:""},
        {name:"Севурс д'Армени",address:"Авеню Луи Блан, Франция",phone:""},
        {name:"Деликатесы Жосеин",address:"Страсбург, Франция",phone:"+ 33 7 81 87 70 46"},
        {name:"Эпик Арм Маркет",address:"Страсбург, Франция",phone:"09-53-70-86-39"},
        {name:"Эписер Афина",address:"Страсбург, Франция",phone:""},
        {name:"Супермаркет Богатырь",address:"Хогстрат 95A, Роттердам, Нидерланды",phone:"+ 31617904128"},
        {name:"Аракс Маркет",address:"Отмарсумссестрат 250, Алмело, Голландия",phone:"0031624263197"},
        {name:"Эписер Файн Эребуниереван",address:"54 д'Ереван, Мулену Центр лес Эпинет",phone:""},
        {name:"Карфур Белле илле",address:"Льеж, Бельгия",phone:"+ 32 4 370 95 00"},
        {name:"ООО «Лудас (Арнак и Людмила Абрамян)»",address:"Бельгия",phone:"+ 32 486 028470 / + 32 484 166475"},
      ]},
      {title:"Россия",icon:"assets/images/flags/russia.svg",list:[
        {name:"Пятерочка Магазин(Армянский павильон)",address:"ул. Сукромка дом 5.,Город Мытищи",phone:"+7 965 419-01-86"},
        {name:"Россия Московская область",address:"г. Реутов улица Парковая д 8. к-3",phone:""},
        {name:"Нижний Новгород",address:"",phone:"+79092905455"},
        {name:"Москва, Мытищи",address:"",phone:"+79166914075"},
        {name:"импортер Андрей",address:"Нижний Новгород",phone:"+79092905455"},
        {name:"Импортер ИП Мирзоян Варужан",address:"Ставрополь, ул. Южный обход 51",phone:"+79097732221"},
      ]},
    ],
    en: [
      {title:"Armenia",icon:"assets/images/flags/armenia.svg",list:[
        {name:"Yerevan City Supermarkets",address:"",phone:""},
        {name:"Nor Zovq Supermarkets",address:"",phone:""},
        {name:"Bonus Supermarket",address:"",phone:""},
        {name:"Citrus Supermarket",address:"Frunze 10/1",phone:""},
        {name:"Nrneni House",address:"Yerevan, Pushkin 21",phone:"+ 374 44 999930 / +374 11 999930"},
        {name:"Pnduk",address:"Yerevan 24 Mashtots Avenue / 40 Mashtots Avenue",phone:""},
        {name:"Opera Market",address:"",phone:""},
        {name:"EAK boutique",address:"Baghramyan 77/5",phone:""},
        {name:"Levon և Lamara Pharmacy Network",address:"",phone:""},
        {name:"EVA beauty store",address:"Yerevan Kiyan Street",phone:"+ 374 94 783381"},
        {name:"Surmalu Trade Center",address:"",phone:""},
        {name:"Petak trade center",address:"",phone:""},
        {name:"Vernissage",address:"Yerevan Khanjyan street",phone:""},
        {name:"Van Market",address:"48 Khorenatsi St., Echmiadzin",phone:""},
        {name:"Original Supermarket",address:"22/10 Hanrapetutyan, Abovyan",phone:""},
        {name:"Safari Supermarket",address:"Charentsavan",phone:""},
        {name:"LA Coffee",address:"Sevan,Sayat-Nova 22",phone:""},
        {name:"Vahagni Market",address:"Ashtarak Highway 7, Vahagni District",phone:""},
        {name:"Zolakar village",address:"Martuni, c. Zolakar, 1st street 4sh.",phone:""},
        {name:"Coffee Tun",address:"Vanadzor,Vardanants street",phone:"+ 374 94 000606"},
        {name: "Duty Free", address: "Zvartnots Airport Departure Hall, Department of Armenian Brands", phone: ""},
      ]},
      {title:"USA",icon:"assets/images/flags/usa.svg",list:[
        {name:"JONS MARKET",address:"",phone:""},
        {name:"Old fashioned deli",address:"",phone:""},
        {name:"Big Square Market ",address:"",phone:""},
        {name:"Blackjack Market",address:"",phone:""},
        {name:"Good fellow / Good Fellow Market & Pizza ",address:"",phone:""},
        {name:"Unique market & liquor / Unique Liquor & Market",address:"",phone:""},
        {name:"Tarzana Armenian deli",address:"",phone:""},
        {name:"Victory produce",address:"",phone:""},
        {name:"Mousaler market",address:"",phone:""},
        {name:"Butcher & booze",address:"",phone:""},
        {name:"Voskevaz produce",address:"",phone:""},
        {name:"Valley market place (Simi Valley)",address:"",phone:""},
        {name:"Sunland produce",address:"",phone:""},
        {name:"Valley market place all 3",address:"",phone:""},
        {name:"Arbat grocery",address:"",phone:""},
        {name:"Nairi meat & deli",address:"",phone:""},
        {name:"Grand foods market",address:"",phone:""},
        {name:"Baklava Factory",address:"",phone:""},
        {name:"Ossy’s Bakery",address:"",phone:""},
        {name:"ELITE MARKET BAKERY & LIQUOR",address:"",phone:""},
        {name:"Victory Produce",address:"",phone:""},
        {name:"Sunshine Bakery and Pastry",address:"",phone:""},
        {name:"SUZY'S GARDEN & DELIGHTS FLOWERS",address:"",phone:""},
        {name:"Burbank Markets and bakery / Red Top Market & Kitchen",address:"",phone:""},
        {name:"Baklava Factory in north Hollywood and Glendale",address:"",phone:""},
        {name:"Van Bakery North Hollywood and Hollywood",address:"",phone:""},
        {name:"Nelly’s Bakery",address:"",phone:""},
        {name:"Tigranakert market",address:"",phone:""},
        {name:"Big Square,LA",address:"",phone:""},
        {name:"Armash Produce,LA",address:"",phone:""},
        {name:"Tigranakert Market,LA",address:"",phone:""},
        {name:"Baklava Factory,LA",address:"",phone:""},
        {name:"Terramo cafe,LA",address:"",phone:""},
        {name:"Victory Produce,LA",address:"",phone:""},
        {name:"Ossy’s Bakery,LA",address:"",phone:""},
        {name:"SAN DIEGO / USA ",address:"",phone:""},
      ]},
      {title:"Europe",icon:"assets/images/flags/european-union.svg",list:[
        {name:"Armenian WORLD",address:"Onze-Lieve-Vrouwestraat 78,Mechelen,Belgium",phone:""},
        {name:"David Markett",address:"Rue du Mambourg 18A, Charleroi,Belgium",phone:"+3271160482"},
        {name:"Royal Armenia Mini Market",address:"Rue Léon Theodor 168, 1090 Jette,Belgium",phone:"+32 2 427 28 93"},
        {name:"Freshmed Fresh Med",address:"Rue de l'escadron 35,Etterbeek,Belgium",phone:"+32 2 736 05 28 "},
        {name:"Saveurs d' Arménie",address:" 219 Avenue Louis Blanc,France",phone:""},
        {name:"Hozain Gourmandises Strasbourg",address:"Strasbourg,France",phone:"+33 7 81 87 70 46"},
        {name:"Épicerie ArmMarket",address:"152, route de Bischwiller,Strasbourg,France",phone:"09-53-70-86-39 "},
        {name:"Epiceri Athena",address:"Strasbourg,France",phone:""},
        {name:"Bogatir Supermarket",address:"Hoogstraat 95A,Rotterdam,NL",phone:"+31617904128"},
        {name:"ARAX Market",address:"Holland Almelo Ootmarsumsestraat 250 7603AP Almelo NL",phone:"0031624263197"},
        {name:"Epiceriefine Erebounierevan",address:"54 rue d Erevan 92130 Issy-les-Moulineaux Сentre les Épinettes",phone:""},
        {name:"Carrefour Belle-île",address:"Rue du Pont Wandre 83,Liège,Belgium",phone:"+32 4 370 95 00 "},
        {name: "Ludas LLC (Arnak and Lyudmila Abrahamyans)", address: "Belgium", phone: "+ 32 486 028470 / + 32 484 166475"},
      ]},
      {title:"Russia",icon:"assets/images/flags/russia.svg",list:[
        {name:"Pyaterochka Shop(Armenian pavilion)",address:"Sukromka house 5, Mytishchi city",phone:"+ 7 965 419-01-86"},
        {name:"Russia Moscow region",address:"Reutov street Parkovaya d 8. k-3",phone:""},
        {name:"Nizhny Novgorod",address:"",phone:"+ 79092905455"},
        {name:"Moscow, Mytishchi",address:"",phone:"+ 79166914075"},
        {name:"Importer Andrey",address:"Nizhny Novgorod",phone:"+79092905455"},
        {name:"Importer IP Mirzoyan Varujan",address:"Stavropol, Yuzhny obkhod street 51",phone:"+79097732221"},
      ]},
    ]
  }
  
}
